import React, { Component, Fragment } from 'react';
import PeopleAssigner from '../../../components/people/PeopleAssigner';
import RoutineSchedule from '../../../components/routines/RoutineSchedule';
import TaskTypeSelector from '../TaskTypeSelector';

import { lodash as _, constants } from '../../../common';
import LocationAssigner from '../../../components/locations/LocationAssigner';
import { ContentDivider } from '../../../components/ux/Dividers';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import classNames from 'classnames';
import { FormActions } from '../../../client';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormEditor from '../../../components/forms/FormEditor';
import { AutoFocusTextField, PercentageInput } from '../../../components/ux/Inputs';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SweetAlert from '../../../components/feedback/SweetAlert';
import Typography from '@material-ui/core/Typography';
import { IOSSwitch } from '../../../components/ux/Switches';

import { MobileIcon, MobileOffIcon, ClearFormIcon } from '../../../components/icons';

const defaultAssignment = {
   noLocationAssignmentType: constants.locationAssignmentTypes.ALL_LOCATIONS.id,
   all: true,
   specific: [],
   at: [],
   within: [],
   atTaggedAs: null
};

const inputStates = {
   DEFINE: 1,
   WHEN: 2,
   WHERE: 3,
   WHO: 4,
   WHAT: 5
};

class RoutineEditor extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.stateRenders = {};
      this.stateRenders[inputStates.DEFINE] = this.renderDefinition.bind(this);
      this.stateRenders[inputStates.WHAT] = this.renderWhat.bind(this);
      this.stateRenders[inputStates.WHERE] = this.renderWhere.bind(this);
      this.stateRenders[inputStates.WHO] = this.renderWho.bind(this);
      this.stateRenders[inputStates.WHEN] = this.renderWhen.bind(this);
   }

   buildFromProps(props) {
      return {
         currentRoutine: props.currentRoutine,
         currentForm: props.currentForm,
         inputState: props.inputState,
         isClearing: false,
         broadcast: false
      };
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const current = this.state.currentRoutine;
      const next = nextProps.currentRoutine;

      const currentInputState = this.state.inputState;
      const nextInputState = nextProps.inputState;

      const currentForm = this.state.currentForm;
      const nextForm = nextProps.currentForm;

      if (
         current.name !== next.name ||
         !nextForm ||
         !currentForm ||
         !_.isEqual(_.sortBy(currentForm.items), _.sortBy(nextForm.items)) ||
         currentInputState !== nextInputState ||
         currentForm.version != nextForm.version
      ) {
         var s = this.buildFromProps(nextProps);
         this.setState(s);
      } else {
      }
   }

   componentDidUpdate() {
      const { broadcast } = this.state;
      const { currentRoutine, currentForm } = this.state;

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ routine: currentRoutine, form: currentForm });
         }

         this.setState({ broadcast: false });
      }
   }

   _onNameChange = (e) => {
      const { currentRoutine } = this.state;

      currentRoutine['name'] = e.target.value;

      this.setState({ currentRoutine, broadcast: true });
   };

   _onRoutineOccurrenceChange = ({ occurs }) => {
      let { currentRoutine } = this.getState();

      currentRoutine = { ...currentRoutine, ...occurs };

      this.setState({ currentRoutine, broadcast: true });
   };

   _onRoutineScheduleChange = ({ occurs, workWindow }) => {
      let { currentRoutine } = this.getState();

      currentRoutine = { ...currentRoutine, ...occurs, ...workWindow };

      this.setState({ currentRoutine, broadcast: true });
   };

   getState = () => {
      return _.cloneDeep(this.state);
   };

   _onAssignmentChanged = ({ assignment }) => {
      let { currentRoutine } = this.getState();

      let actionedAt = assignment;

      currentRoutine = { ...currentRoutine, actionedAt };

      this.setState({ currentRoutine, broadcast: true });
   };

   _onWhoChanged = ({ who }) => {
      let { currentRoutine } = this.getState();

      currentRoutine = { ...currentRoutine, who };

      this.setState({ currentRoutine, broadcast: true });
   };

   _onSignoffChanged = ({ who }) => {
      let { currentRoutine } = this.getState();

      const signoff = {
         requiresSignoff: true,
         noSignoffAssignmentType: who.noWhoAssignmentType,
         teams: who.teams,
         positions: who.positions,
         staff: who.staff
      };

      currentRoutine = { ...currentRoutine, signoff };

      this.setState({ currentRoutine, broadcast: true });
   };

   _onRequiresSignoffChange = (event) => {
      const requiresSignoff = event.target.checked;
      let { currentRoutine } = this.state;

      currentRoutine.signoff.requiresSignoff = requiresSignoff;

      this.setState({ currentRoutine, broadcast: true });
   };

   _onWhatChanged = (form) => {
      const { name, description, noForm, items, scoring } = form;

      FormActions.setupFormPreview({
         name,
         description,
         noForm,
         items,
         scoringEnabled: scoring.enabled,
         scoreVisible: scoring.scoreVisible
      });

      this.setState({ currentForm: form, broadcast: true }, () => {
         //FormActions.dump();
      });
   };

   renderWho() {
      const { currentRoutine } = this.state;
      const { name } = currentRoutine;
      const { availableLocations, availableTags, availableTeams, availablePositions, availableStaff, inputState } = this.props;

      const {
         idOccurrenceType,
         dayFlags,
         datesFlags,
         monthFlags,
         cadenceFlags,
         idCadenceType,
         cadence,
         repeatsUntilDateLocal,
         dueByDateLocal,
         dueByTime,
         noSlotDueBy,
         startFromTime,
         noSlotStartFrom,
         actionedAt,
         who,
         signoff
      } = currentRoutine;

      const occurs = { idOccurrenceType, dayFlags, datesFlags, monthFlags, cadenceFlags, idCadenceType, cadence, repeatsUntilDateLocal };

      const { requiresSignoff, noSignoffAssignmentType } = signoff;

      const signoffWho = {
         noWhoAssignmentType: noSignoffAssignmentType,
         noWhoUnionType: 1,
         teams: signoff.teams,
         positions: signoff.positions,
         staff: signoff.staff
      };

      const workWindow = { dueByDateLocal, dueByTime, noSlotDueBy, startFromTime, noSlotStartFrom };

      return (
         <div className='d-flex justify-content-start flex-column wizard-step'>
            <ContentDivider caption={'Who should do this?'} hideDivider />
            <PeopleAssigner
               who={who}
               teams={availableTeams}
               positions={availablePositions}
               staff={availableStaff}
               onChange={this._onWhoChanged}
            />
            <br />
            {
               <FormControlLabel
                  control={<Switch color='primary' checked={requiresSignoff} onChange={this._onRequiresSignoffChange} />}
                  label={'Sign off required for this operation.'}
               />
            }
            {requiresSignoff && (
               <PeopleAssigner
                  unassignedLabel={'select who should signoff this operation.'}
                  showUnionSelector={false}
                  readOnly={!requiresSignoff}
                  who={signoffWho}
                  teams={availableTeams}
                  positions={availablePositions}
                  staff={availableStaff}
                  onChange={this._onSignoffChanged}
               />
            )}
         </div>
      );
   }

   renderWhere() {
      const { currentRoutine } = this.state;
      const { name } = currentRoutine;
      const { availableLocations, availableTags, availableTeams, availablePositions, availableStaff, inputState } = this.props;

      const {
         idOccurrenceType,
         dayFlags,
         datesFlags,
         monthFlags,
         cadenceFlags,
         idCadenceType,
         cadence,
         repeatsUntilDateLocal,
         dueByDateLocal,
         dueByTime,
         noSlotDueBy,
         startFromTime,
         noSlotStartFrom,
         actionedAt,
         who
      } = currentRoutine;

      const occurs = { idOccurrenceType, dayFlags, datesFlags, monthFlags, cadenceFlags, idCadenceType, cadence, repeatsUntilDateLocal };

      const workWindow = { dueByDateLocal, dueByTime, noSlotDueBy, startFromTime, noSlotStartFrom };

      return (
         <div className='d-flex justify-content-start flex-column wizard-step'>
            <ContentDivider caption={'Where should this be done?'} hideDivider />
            <LocationAssigner
               availableLocations={availableLocations}
               availableTags={availableTags}
               readOnly={false}
               onChange={this._onAssignmentChanged}
               assignment={actionedAt}
               unassignedLabel={'Click to specify locations'}
               allLocationsLabel={'at all locations'}
               allLocationsOption={'all locations'}
               workQuestionLabel={'Where is this task perfomed?'}
               actionWord={''}
               forceSpecificLocation
               atCertainLocationsOption={'at specific locations'}
            />
            <br />
            <br />
         </div>
      );
   }

   renderWhen() {
      const { currentRoutine } = this.state;
      const { name } = currentRoutine;
      const { availableLocations, availableTags, availableTeams, availablePositions, availableStaff, inputState } = this.props;

      const {
         idOccurrenceType,
         dayFlags,
         datesFlags,
         monthFlags,
         cadenceFlags,
         idCadenceType,
         cadence,
         repeatsUntilDateLocal,
         dueByDateLocal,
         dueByTime,
         noSlotDueBy,
         startFromTime,
         noSlotStartFrom,
         actionedAt,
         who
      } = currentRoutine;

      const occurs = { idOccurrenceType, dayFlags, datesFlags, monthFlags, cadenceFlags, idCadenceType, cadence, repeatsUntilDateLocal };

      const workWindow = { dueByDateLocal, dueByTime, noSlotDueBy, startFromTime, noSlotStartFrom };

      return (
         <div className='d-flex justify-content-start flex-column wizard-step'>
            <ContentDivider caption={'How often should this be done?'} hideDivider />
            <RoutineSchedule occurs={occurs} workWindow={workWindow} onChange={this._onRoutineScheduleChange} />
            <br />
            <br />
         </div>
      );
   }

   _onTaskTypeChange = (taskType) => {
      let { currentRoutine } = this.getState();

      currentRoutine = { ...currentRoutine, ...{ noTaskType: taskType.no } };

      this.setState({ currentRoutine, broadcast: true });
   };

   _onTogglePreview = () => {
      const { onTogglePreview } = this.props;
      onTogglePreview();
   };

   _onClearFormRequested = () => {
      this.setState({ isClearing: true });
   };

   _onClearFormCancelled = () => {
      this.setState({ isClearing: false });
   };

   _onClearFormConfirmed = () => {
      const { onClearForm } = this.props;
      onClearForm();
      this.setState({ isClearing: false });
   };

   _onPointsScoringEnabledChange = (event) => {
      const enabled = event.target.checked;
      let { currentRoutine } = this.state;

      currentRoutine.scoring.enabled = enabled;

      this.setState({ currentRoutine, broadcast: true });
   };

   _onShowScoreToStaffChanged = (event) => {
      const scoreVisible = event.target.checked;
      let { currentRoutine } = this.state;

      currentRoutine.scoring.scoreVisible = scoreVisible;

      this.setState({ currentRoutine, broadcast: true });
   };

   _onShowFinalGradeToStaffChanged = (event) => {
      const finalGradeVisible = event.target.checked;
      let { currentRoutine } = this.state;

      currentRoutine.scoring.finalGradeVisible = finalGradeVisible;

      this.setState({ currentRoutine, broadcast: true });
   };

   _onPassFailEnabledChanged = (event) => {
      const passFailEnabled = event.target.checked;
      let { currentRoutine } = this.state;

      let { scoring } = currentRoutine;
      // ensure we set the threshold on the first enable
      if (scoring.passFailThreshold == null && passFailEnabled) {
         currentRoutine.scoring.passFailThreshold = 50;
      }

      currentRoutine.scoring.passFailEnabled = passFailEnabled;

      this.setState({ currentRoutine, broadcast: true });
   };

   _onPassFailThresholdChanged = (e, val) => {
      const passFailThreshold = val;
      let { currentRoutine } = this.state;

      currentRoutine.scoring.passFailThreshold = passFailThreshold;

      this.setState({ currentRoutine, broadcast: true });
   };

   renderDefinition() {
      const { availableTaskTypes } = this.props;
      const { currentRoutine } = this.state;
      const { name, noTaskType, scoring } = currentRoutine;

      const selectedTaskType = { no: noTaskType };

      const { enabled, scoreVisible, finalGradeVisible, passFailEnabled, passFailThreshold } = scoring;

      return (
         <div className='d-flex justify-content-start flex-column wizard-step'>
            <AutoFocusTextField
               id='name'
               label='Name the operation'
               margin='normal'
               defaultValue={name}
               onChange={(e) => this._onNameChange(e)}
               variant='outlined'
               autoFocus={true}
            />

            <TaskTypeSelector
               label={'Type of operation'}
               required={true}
               taskTypes={availableTaskTypes}
               taskType={selectedTaskType}
               onChange={this._onTaskTypeChange}
            />

            <br />
            <ContentDivider caption={'Scoring'} hideDivider />
            <div className='scoring-section'>
               <div className='block'>
                  <FormControlLabel
                     control={<Switch color='primary' checked={enabled} onChange={this._onPointsScoringEnabledChange} />}
                     label={'Allow point scoring '}
                  />

                  <FormControlLabel
                     control={<Switch color='primary' checked={passFailEnabled} onChange={this._onPassFailEnabledChanged} />}
                     label={'Pass / Fail ops based on a percentage score'}
                  />
                  {passFailEnabled && (
                     <PercentageInput
                        className={'pass-threshold-percent'}
                        label={''}
                        value={passFailThreshold}
                        onChange={this._onPassFailThresholdChanged}
                     />
                  )}
               </div>
               <div className='block'>
                  <FormControlLabel
                     control={<Switch color='primary' checked={scoreVisible} onChange={this._onShowScoreToStaffChanged} />}
                     label={'Show the score to staff completing tasks'}
                  />
                  <FormControlLabel
                     control={<Switch color='primary' checked={finalGradeVisible} onChange={this._onShowFinalGradeToStaffChanged} />}
                     label={'Final grade shown to staff completing tasks'}
                  />
               </div>
            </div>
         </div>
      );
   }

   renderWhat() {
      const { currentForm, isClearing } = this.state;
      const { availableLocations, availableTags, previewing } = this.props;

      if (!currentForm) {
         return null;
      }

      const allowActions = currentForm.items.length > 0;

      return (
         <>
            <div className='header-row'>
               <ContentDivider className={'what-divider'} caption={'What needs to be done?'} hideDivider />
               <span style={{ flex: 1 }}></span>

               <div className='action-icons'>
                  <Tooltip title={previewing ? 'Hide preview' : 'Show preview'}>
                     <IconButton disabled={!allowActions} className='btn-preview' edge='end' onClick={() => this._onTogglePreview()}>
                        {previewing ? <MobileOffIcon /> : <MobileIcon />}
                     </IconButton>
                  </Tooltip>
                  <Tooltip title='Remove all items'>
                     <IconButton disabled={!allowActions} className='btn-clearform' edge='end' onClick={() => this._onClearFormRequested()}>
                        <ClearFormIcon />
                     </IconButton>
                  </Tooltip>
               </div>
            </div>

            <FormEditor
               availableLocations={availableLocations}
               availableTags={availableTags}
               currentForm={currentForm}
               onChange={this._onWhatChanged}
            />
            {isClearing && (
               <SweetAlert
                  show={true}
                  warning
                  showCancel
                  confirmBtnText={'Yes'}
                  cancelBtnText={'No'}
                  confirmBtnBsStyle='warning'
                  cancelBtnBsStyle='default'
                  title={'Remove all items?'}
                  onConfirm={this._onClearFormConfirmed}
                  onCancel={this._onClearFormCancelled}>
                  <Typography>Are you sure you want to clear the form</Typography>
               </SweetAlert>
            )}
         </>
      );
   }

   renderForState(inputState) {
      return <div>{this.stateRenders[inputState]()}</div>;
   }

   _onTabChange = (event, value) => {
      this.props.onStateChange({ inputState: value });
   };

   renderTabs() {
      const { inputState } = this.state;

      const tabClasses = classNames('tabs-bar', { wide: inputState === inputStates.WHAT });

      return (
         <Fragment>
            <Tabs
               className={tabClasses}
               variant='scrollable'
               scrollButtons='on'
               indicatorColor='primary'
               textColor='primary'
               value={inputState}
               onChange={this._onTabChange}>
               <Tab value={inputStates.DEFINE} className='tab' label='The basics' />
               <Tab value={inputStates.WHEN} className='tab' label='When' />
               <Tab value={inputStates.WHERE} className='tab' label='Where' />
               <Tab value={inputStates.WHO} className='tab' label='Who' />
               <Tab value={inputStates.WHAT} className='tab' label='What' />
            </Tabs>

            {this.renderForState(inputState)}
         </Fragment>
      );
   }

   render() {
      const { inputState, showTabs } = this.props;

      return (
         <div className='RoutineEditor'>
            {showTabs && this.renderTabs()}
            {!showTabs && this.renderForState(inputState)}
         </div>
      );
   }
}

RoutineEditor.inputStates = inputStates;

RoutineEditor.defaultProps = {
   onChange: () => {},
   onStateChange: () => {},
   onTogglePreview: () => {},
   onClearForm: () => {},
   availableLocations: [],
   availableTeams: [],
   availablePositions: [],
   availableStaff: [],
   availableTags: [],
   availableTaskTypes: [],
   inputState: inputStates.DEFINE,
   showTabs: true
};

export default RoutineEditor;
