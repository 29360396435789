import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { lodash as _ } from '../../../common';
//import TeamChip from '../../.components/teams/TeamChip';
import TeamChip from '../../../components/teams/TeamChip';
import PositionChip from '../../../components/positions/PositionChip';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

import { translate } from '../../../l10n';

const buildPeopleOptions = ({ teams, positions, staff }) => {
   let options = [];

   options = options.concat(
      _.map(teams, (x) => {
         const firstLetter = x.name[0].toUpperCase();
         return {
            no: x.no,
            title: x.name,
            name: x.name,
            type: 'team',
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            key: `t${x.no}`
         };
      })
   );
   options = options.concat(
      _.map(positions, (x) => {
         const firstLetter = x.name[0].toUpperCase();
         return {
            no: x.no,
            title: x.name,
            name: x.name,
            type: 'position',
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            key: `p${x.no}`
         };
      })
   );

   options = options.concat(
      _.map(staff, (x) => {
         const firstLetter = x.name[0].toUpperCase();
         return {
            no: x.noUser,
            title: x.name,
            name: x.name,
            type: 'staff',
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            key: `s${x.noUser}`
         };
      })
   );

   return options;
};

const PeopleSelector = (props) => {
   const { teams, positions, staff, variant = 'outlined', required = false, onChange, who, label = 'Assigned to' } = props;
   const [value, setValue] = useState([]);
   const [options, setOptions] = useState([]);
   const [placeholder, setPlaceholder] = useState('type to select');

   useEffect(() => {
      console.log('Teams changed!');

      if (options.length == 0) {
         var newOptions = buildPeopleOptions({ teams, positions, staff });
         setOptions(newOptions);
      }
   }, [teams, positions, staff]);

   useEffect(() => {
      buildValue(who, options);
   }, [who, options]);

   const buildValue = (who, newOptions) => {
      let selectedPositions = who.positions;
      let selectedTeams = who.teams;
      let selectedStaff = who.staff;

      let newValue = _.filter(newOptions, (o) => {
         return (
            _.findIndex(selectedPositions, (x) => {
               return `p${x.no}` == o.key;
            }) > -1 ||
            _.findIndex(selectedTeams, (x) => {
               return `t${x.no}` == o.key;
            }) > -1 ||
            _.findIndex(selectedStaff, (x) => {
               return `s${x.no}` == o.key;
            }) > -1
         );
      });

      setValue(newValue);
   };

   const onLocalChange = (evt, newValue) => {
      let selectedPositions = _.filter(newValue, (x) => {
         return x.type == 'position';
      });
      let selectedTeams = _.filter(newValue, (x) => {
         return x.type == 'team';
      });
      let selectedStaff = _.filter(newValue, (x) => {
         return x.type == 'staff';
      });

      let actualPositions = _.filter(positions, (l) => {
         return (
            _.findIndex(selectedPositions, (sl) => {
               return sl.no == l.no;
            }) > -1
         );
      });

      let actualTeams = _.filter(teams, (l) => {
         return (
            _.findIndex(selectedTeams, (sl) => {
               return sl.no == l.no;
            }) > -1
         );
      });

      let actuaStaff = _.filter(staff, (l) => {
         return (
            _.findIndex(selectedStaff, (sl) => {
               return sl.no == l.noUser;
            }) > -1
         );
      });

      if (onChange) {
         let newWho = _.cloneDeep(who);

         newWho.positions = _.map(actualPositions, (x) => {
            return { no: x.no };
         });
         newWho.teams = _.map(actualTeams, (x) => {
            return { no: x.no };
         });
         newWho.staff = _.map(actuaStaff, (x) => {
            return { no: x.noUser };
         });

         onChange(newWho);
      }
   };

   return (
      <div className={'PeopleSelector'}>
         <Autocomplete
            multiple
            shrink='true'
            disableCloseOnSelect
            //disableClearable={required}
            //filterSelectedOptions
            //blurOnSelect={false}
            value={value}
            //defaultValue={value}
            options={options}
            onChange={onLocalChange}
            disablePortal={true}
            groupBy={(option) => option.type}
            //getOptionLabel={(option) => option.name}
            //style={{ width: 300 }}
            //renderInput={(params) => <TextField {...params} label='With categories' variant='outlined' />}

            //renderOption={renderOptionWrapper}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => {
               console.log('option!', option);
               return (
                  <div data-ignore-popper-toggle style={{ width: '100%' }} key={`option-${option.key}`}>
                     <Checkbox style={{ marginRight: 8 }} checked={selected} />
                     {option.name}
                  </div>
               );
            }}
            renderInput={(params) => (
               <TextField
                  {...params}
                  InputLabelProps={{
                     shrink: true
                  }}
                  variant={variant}
                  label={label}
                  placeholder={placeholder}
               />
            )}
            renderTags={(tagValue, getTagProps) => {
               return tagValue.map((option, index) => {
                  return (
                     <div key={`tag-${option.key}`}>
                        {option.type == 'position' && (
                           <PositionChip
                              key={`pos-chip-${option.key}`}
                              {...getTagProps({ index })}
                              name={option.name}
                              disableDelete={required && selectedLocations.length == 1}
                           />
                        )}
                        {option.type == 'team' && (
                           <TeamChip
                              key={`team-chip-${option.key}`}
                              {...getTagProps({ index })}
                              name={option.name}
                              disableDelete={required && selectedLocations.length == 1}
                           />
                        )}
                        {option.type == 'staff' && (
                           <Chip
                              key={`staff-chip-${option.key}`}
                              label={option.name}
                              {...getTagProps({ index })}
                              name={option.name}
                              //disableDelete={required && selectedLocations.length == 1}
                           />
                        )}
                     </div>
                  );
               });
            }}
         />
      </div>
   );
};
export default PeopleSelector;
