const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const constants = require('../../constants');
const { reportStates, whoAssignmentTypes, whoUnionType } = constants;

class Report extends AggregateRoot {
   constructor({ user, logger, sequenceProvider }, aid) {
      super({ user, logger });

      this.sequenceProvider = sequenceProvider;
   }

   async initNew({ name, description, noReportType, restrictedToPlaceOfWork }) {
      const tid = this.domainUser.tid;

      let noReport = await this.sequenceProvider.getNextReportNo({ tid });

      let p = {
         aid: newGuid(),
         noReport,
         name,
         description,
         noReportType,
         restrictedToPlaceOfWork,
         who: {
            noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
            noWhoUnionType: whoUnionType.NONE.id,
            teams: [],
            positions: [],
            staff: []
         },
         options: { filters: [], groups: [], analysis: this.buildDefaultAnalysis() },
         state: reportStates.DISABLED,
         tid
      };

      this.apply('REPORT_ADDED', p);

      var o = _.omit(p, ['aid', 'tid']);

      this.setData(
         {
            properties: _.merge(
               {
                  id: p.aid,
                  deleted: false
               },
               o,
               {
                  tags: []
               }
            )
         },
         true
      );
   }

   enable() {
      var p = {};

      p.noReport = this._properties.noReport;
      p.state = this._properties.state & ~reportStates.DISABLED;

      this.apply('REPORT_ENABLED', p);

      this._properties.state = this._properties.state & ~reportStates.DISABLED;
   }

   disable() {
      var p = {};

      p.noReport = this._properties.noReport;
      p.state = this._properties.state | reportStates.DISABLED;

      this.apply('REPORT_DISABLED', p);

      this._properties.state = this._properties.state | reportStates.DISABLED;
   }

   updateProperties({ name, description, restrictedToPlaceOfWork }) {
      if (!name) {
         throw new DomainException('A report needs a name.');
      }

      const before = _.pick(this._properties, ['name', 'description', 'restrictedToPlaceOfWork']);

      var p = { after: { name, description, restrictedToPlaceOfWork }, before, noReport: this._properties.noReport };

      this.apply('REPORT_PROPS_UPDATED', p);

      this._properties.name = p.after.name;
      this._properties.description = p.after.description;
      this._properties.restrictedToPlaceOfWork = p.after.restrictedToPlaceOfWork;
   }

   updateOptions({ options }) {
      const before = _.pick(this._properties, ['options']);

      var p = { after: { options }, before, noReport: this._properties.noReport };

      this.apply('REPORT_OPTIONS_UPDATED', p);

      this._properties.options = p.after.options;
   }

   tag({ tag }) {
      const { noTag, name, noTagType, noParent } = tag;

      this._properties.tags = this._properties.tags || [];
      let tags = this._properties.tags;

      var i = _.find(tags, (x) => {
         return x.noTag == noTag;
      });
      if (i) {
         return;
      } // already tagged

      const p = {
         name,
         noTagType,
         noTag,
         noReport: this._properties.noReport,
         idReport: this.id
      };

      this.apply('REPORT_TAGGED', p);

      tags.push({ noTag });
   }

   untag({ tag }) {
      if (!tag) {
         return;
      }

      const { noTag, name, noTagType, noParent } = tag;

      this._properties.tags = this._properties.tags || [];
      let tags = this._properties.tags;

      var i = _.find(tags, (x) => {
         return x.noTag == noTag;
      });
      if (!i) {
         return;
      } // already untagged

      const p = {
         name,
         noTagType,
         noTag,
         noReport: this._properties.noReport,
         idReport: this.id
      };

      this.apply('REPORT_UNTAGGED', p);

      var slx = _.findIndex(tags, (x) => {
         return x.noTag === p.noTag;
      });
      if (slx > -1) {
         tags.splice(slx, 1);
      }
   }

   delete() {
      var p = { deleted: true };
      p.noReport = this._properties.noReport;

      this.apply('REPORT_DELETED', p);

      this._properties.deleted = true;
   }

   get agt() {
      return 'Report';
   }

   assignAllStaff({ noWhoUnionType }) {
      var p = {
         noReport: this._properties.noReport,
         noWhoUnionType: noWhoUnionType,
         noWhoAssignmentType: whoAssignmentTypes.ALL_STAFF.id
      };

      this.apply('REPORT_ASSIGNEDTO_ALL_STAFF', p);

      let who = this._properties.who;

      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = p.noWhoUnionType;
      who.teams = [];
      who.positions = [];
      who.staff = [];
   }

   assignAllTeams({ noWhoUnionType }) {
      var p = {
         noReport: this._properties.noReport,
         noWhoUnionType: noWhoUnionType,
         noWhoAssignmentType: whoAssignmentTypes.ALL_TEAMS.id
      };

      this.apply('REPORT_ASSIGNEDTO_ALL_TEAMS', p);

      let who = this._properties.who;

      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = p.noWhoUnionType;
      who.teams = [];
      who.positions = [];
      who.staff = [];
   }

   assignAllPositions({ noWhoUnionType }) {
      var p = {
         noReport: this._properties.noReport,
         noWhoUnionType: noWhoUnionType,
         noWhoAssignmentType: whoAssignmentTypes.ALL_POSITIONS.id
      };

      this.apply('REPORT_ASSIGNEDTO_ALL_POSITIONS', p);

      let who = this._properties.who;

      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = p.noWhoUnionType;
      who.teams = [];
      who.positions = [];
      who.staff = [];
   }

   assignSpecificTeams({ nosTeam, noWhoUnionType, noWhoAssignmentType }) {
      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignStaff = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignPositions ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignStaff ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id : newWhoAssignmentType;

      var p = {
         nosTeam,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id,
         noReport: this._properties.noReport,
         noWhoUnionType: noWhoUnionType,
         willAssignPositions,
         willAssignStaff
      };

      this.apply('REPORT_ASSIGNEDTO_SPECIFIC_TEAMS', p);

      let who = this._properties.who;
      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = noWhoUnionType;
      who.teams = _.map(nosTeam, (l) => {
         return { no: l };
      });
      who.positions = willAssignPositions ? who.positions : [];
      who.staff = willAssignStaff ? who.staff : [];
   }

   assignSpecificPositions({ nosPosition, noWhoUnionType, noWhoAssignmentType }) {
      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignTeams = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;
      let willAssignStaff = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignTeams ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignStaff ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id : newWhoAssignmentType;

      var p = {
         nosPosition,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id,
         noReport: this._properties.noReport,
         noWhoUnionType: noWhoUnionType,
         willAssignTeams,
         willAssignStaff
      };

      this.apply('REPORT_ASSIGNEDTO_SPECIFIC_POSITIONS', p);

      let who = this._properties.who;
      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = noWhoUnionType;
      who.positions = _.map(nosPosition, (l) => {
         return { no: l };
      });
   }

   assignSpecificStaff({ nosStaff, noWhoUnionType, noWhoAssignmentType }) {
      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignTeams = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignTeams ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignPositions ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id : newWhoAssignmentType;

      var p = {
         nosStaff,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id,
         noReport: this._properties.noReport,
         noWhoUnionType: noWhoUnionType,
         willAssignPositions,
         willAssignTeams
      };

      this.apply('REPORT_ASSIGNEDTO_SPECIFIC_STAFF', p);

      let who = this._properties.who;
      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = noWhoUnionType;
      who.staff = _.map(nosStaff, (l) => {
         return { no: l };
      });
   }

   unassignFromEveryone() {
      var p = {
         noReport: this._properties.noReport,
         noWhoUnionType: whoUnionType.NONE.id,
         noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id
      };

      this.apply('REPORT_UNASSIGNED_FROM_EVERYONE', p);

      let who = this._properties.who;

      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = p.noWhoUnionType;
      who.teams = [];
      who.positions = [];
      who.staff = [];
   }

   /* =================== */

   buildDefaultAnalysis() {
      return {
         slice: {
            rows: [
               {
                  uniqueName: 'Location'
               },
               {
                  uniqueName: 'Operation'
               }
            ],
            columns: [
               {
                  uniqueName: 'Measures'
               }
            ],
            measures: [
               {
                  uniqueName: '% Not Due',
                  formula: '(sum("Not Due") / sum("Total Ops")) * 100',
                  caption: '% Not Due',
                  format: '5t0hyyv5'
               },
               {
                  uniqueName: '% Due',
                  formula: '(sum("Due") / sum("Total Ops")) * 100',
                  caption: '% Due',
                  format: '5t0hyyv5'
               },
               {
                  uniqueName: '% Overdue',
                  formula: '(sum("Overdue") / sum("Total Ops")) * 100',
                  caption: '% Overdue',
                  format: '5t0hyyv5'
               },
               {
                  uniqueName: '% Missed',
                  formula: '(sum("Missed") / sum("Total Ops")) * 100',
                  caption: '% Missed',
                  format: '5t0hyyv5'
               },
               {
                  uniqueName: '% Completed',
                  formula: '(sum("Completed") / sum("Total Ops")) * 100',
                  caption: '% Completed',
                  format: '5t0hyyv5'
               }
            ]
         },
         formats: [
            {
               name: '5t0hyyv5',
               thousandsSeparator: ' ',
               decimalSeparator: '.',
               decimalPlaces: 2,
               currencySymbol: '',
               currencySymbolAlign: 'left',
               nullValue: '',
               textAlign: 'right',
               isPercent: false
            }
         ]
      };
   }
}

module.exports = Report;
