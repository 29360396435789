import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;
const { reportTypes } = constants;

function TaskReportActions() {
   var Actions = Refluxr.createActions(
      [
         'selectTaskReportSync',
         'unselectTaskReportSync',
         'unselectAllTaskReportsSync',
         'selectAllTaskReportsOnThisPageSync',
         'setTaskReportPagingFiltersSync',
         {
            addTaskReport: OnlineApi.send,
            prepare: function ({ name, description = null, restrictedToPlaceOfWork, who }) {
               return {
                  messageType: 'ADD_REPORT',
                  noReportType: reportTypes.TASK.id,
                  name,
                  description,
                  restrictedToPlaceOfWork,
                  who
               };
            }
         },
         {
            updateTaskReportProperties: OnlineApi.send,
            prepare: function ({ noReport, name, description = null, who, restrictedToPlaceOfWork }) {
               return {
                  messageType: 'UPDATE_REPORT',
                  noReport: noReport,
                  name,
                  description,
                  who,
                  restrictedToPlaceOfWork,
                  updateSections: ['properties', 'who']
               };
            }
         },
         {
            updateTaskReportSettings: OnlineApi.send,
            prepare: function ({ noReport, filters = [], groups = [] }) {
               return {
                  messageType: 'UPDATE_REPORT',
                  noReport: noReport,
                  options: {
                     filters,
                     groups
                  },
                  updateSections: ['settings']
               };
            }
         },
         {
            updateTaskReportAnalysis: OnlineApi.send,
            prepare: function ({ noReport, analysis }) {
               return {
                  messageType: 'UPDATE_REPORT',
                  noReport: noReport,
                  options: {
                     analysis
                  },
                  updateSections: ['analyse']
               };
            }
         },
         {
            updateTaskReport: OnlineApi.send,
            prepare: function ({ noReport, name, description = null, filters = [] }) {
               return {
                  messageType: 'UPDATE_REPORT',
                  noReport: noReport,
                  name,
                  description,
                  options: {
                     filters
                  },
                  updateSections: ['properties', 'settings']
               };
            }
         },
         {
            tagReports: OnlineApi.send,
            prepare: function ({ name, nosReport }) {
               return {
                  messageType: 'TAG_REPORTS',
                  nosReport,
                  name
               };
            }
         },
         {
            untagReports: OnlineApi.send,
            prepare: function ({ name, nosReport }) {
               return {
                  messageType: 'UNTAG_REPORTS',
                  nosReport,
                  name
               };
            }
         },
         {
            deleteReports: OnlineApi.send,
            prepare: function ({ nosReport }) {
               return {
                  messageType: 'DELETE_REPORTS',
                  nosReport
               };
            }
         },
         {
            enableReports: OnlineApi.send,
            prepare: function ({ nosReport }) {
               return {
                  messageType: 'TOGGLE_REPORTS',
                  nosReport,
                  enabled: true
               };
            }
         },
         {
            disableReports: OnlineApi.send,
            prepare: function ({ nosReport }) {
               return {
                  messageType: 'TOGGLE_REPORTS',
                  nosReport,
                  enabled: false
               };
            }
         },
         {
            selectAllPossibleTaskReports: OnlineApi.send,
            prepare: function ({ withTags = [], withoutTags = [], searchText = '' }) {
               const o = {
                  messageType: 'FETCH_REPORTS',
                  noReportType: 1,
                  searchText,
                  filters: [],
                  forSelect: true,
                  withTags,
                  withoutTags
               };

               return o;
            }
         },
         {
            loadReport: OnlineApi.send,
            prepare: function ({ noReport }) {
               const o = {
                  messageType: 'FETCH_REPORTS',
                  noReportType: 1,
                  searchText: null,
                  filters: [{ field: 'no', value: noReport }],
                  withTags: [],
                  withoutTags: [],
                  limit: 1,
                  page: 1,
                  forSelect: false
               };

               return o;
            }
         },
         {
            fetchReports: OnlineApi.send,
            prepare: function ({ page = 1, limit = 10, withTags = [], withoutTags = [], searchText = '' }) {
               const o = {
                  messageType: 'FETCH_REPORTS',
                  noReportType: 1,
                  searchText,
                  filters: [],
                  withTags,
                  withoutTags,
                  limit,
                  page,
                  forSelect: false
               };

               return o;
            }
         }
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

TaskReportActions.instance = new TaskReportActions();

export default TaskReportActions.instance;
