const _ = require('../../../lodash');
const constants = require('../../../constants');

const { whoAssignmentTypes, whoUnionType } = constants;

class WhoByCalculator {
   constructor(peopleDomainService) {
      this.peopleDomainService = peopleDomainService;
   }

   calculateWhoShouldDo({ who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations }) {
      switch (true) {
         case who.noWhoUnionType === whoUnionType.ANY.id:
            return this.calculateWhoShouldDoForAny({ who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations });
         case who.noWhoUnionType === whoUnionType.ALL.id:
            return this.calculateWhoShouldDoForAll({ who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations });
         default:
            throw new Error(`union type not supported - calculateWhoShouldDo ${who.noWhoUnionType}`);
      }
   }

   //// Private

   calculateAnyAllTeams({ assignment, who, noLocation, availableTeams, availableLocations }) {
      let allowedTeams = this.peopleDomainService.determineAssignedTeams({ teams: availableTeams, locations: availableLocations, noLocation });

      if (allowedTeams.length > 0) {
         assignment.noWhoAssignmentType = assignment.noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id;
         assignment.teams = allowedTeams;
      }
   }

   calculateAnySpecificTeams({ assignment, who, noLocation, availableTeams, availableLocations }) {
      let allowedTeams = this.peopleDomainService.determineAssignedTeams({ teams: availableTeams, locations: availableLocations, noLocation });

      let allowedNos = _.map(allowedTeams, (at) => {
         return { no: at.no };
      });

      const matchingTeams = _.intersectionBy(who.teams, allowedNos, 'no');

      if (matchingTeams.length > 0) {
         assignment.noWhoAssignmentType = assignment.noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id;
         assignment.teams = matchingTeams;
      }
   }

   calculateAnyAllPositions({ assignment, who, noLocation, availablePositions, availableTeams, availableLocations }) {
      let allowedPositions = this.peopleDomainService.determineAssignedPositions({
         positions: availablePositions,
         teams: availableTeams,
         locations: availableLocations,
         noLocation
      });

      let allowedNos = _.map(allowedPositions, (at) => {
         return { no: at.no };
      });

      if (allowedNos.length > 0) {
         assignment.noWhoAssignmentType = assignment.noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id;
         assignment.positions = allowedNos;
      }
   }

   calculateAnySpecificPositions({ assignment, who, noLocation, availablePositions, availableTeams, availableLocations }) {
      let allowedPositions = this.peopleDomainService.determineAssignedPositions({
         positions: availablePositions,
         teams: availableTeams,
         locations: availableLocations,
         noLocation
      });

      let allowedNos = _.map(allowedPositions, (at) => {
         return { no: at.no };
      });

      const matchingPositions = _.intersectionBy(who.positions, allowedNos, 'no');

      if (matchingPositions.length > 0) {
         assignment.noWhoAssignmentType = assignment.noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id;
         assignment.positions = matchingPositions;
      }
   }

   calculateAnyAllStaffMembers({ assignment, who, noLocation, availableStaffMembers, availableLocations }) {
      let allowedStaffMembers = this.peopleDomainService.determineAssignedStaffMembers({
         staffMembers: availableStaffMembers,
         locations: availableLocations,
         noLocation
      });

      let allowedNos = _.map(allowedStaffMembers, (at) => {
         return { no: at.noUser };
      });

      if (allowedNos.length > 0) {
         assignment.noWhoAssignmentType = assignment.noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id;
         assignment.staff = allowedNos;
      }
   }

   calculateAnySpecificStaffMembers({ assignment, who, noLocation, availableStaffMembers, availableLocations }) {
      let allowedStaffMembers = this.peopleDomainService.determineAssignedStaffMembers({
         staffMembers: availableStaffMembers,
         locations: availableLocations,
         noLocation
      });

      let allowedNos = _.map(allowedStaffMembers, (at) => {
         return { no: at.noUser };
      });

      const matchingStaffMembers = _.intersectionBy(who.staff, allowedNos, 'no');

      if (matchingStaffMembers.length > 0) {
         assignment.noWhoAssignmentType = assignment.noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id;
         assignment.staff = matchingStaffMembers;
      }
   }

   calculateWhoShouldDoForAny({ who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations }) {
      let assignment = {
         noWhoAssignmentType: 0,
         noWhoUnionType: whoUnionType.ANY.id
      };

      if ((who.noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id) {
         this.calculateAnySpecificTeams({ assignment, who, noLocation, availableTeams, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id) {
         this.calculateAnySpecificPositions({ assignment, who, noLocation, availablePositions, availableTeams, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id) {
         this.calculateAnySpecificStaffMembers({ assignment, who, noLocation, availableStaffMembers, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.ALL_TEAMS.id) == whoAssignmentTypes.ALL_TEAMS.id) {
         this.calculateAnyAllTeams({ assignment, who, noLocation, availableTeams, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.ALL_POSITIONS.id) == whoAssignmentTypes.ALL_POSITIONS.id) {
         this.calculateAnyAllPositions({ assignment, who, noLocation, availablePositions, availableTeams, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.ALL_STAFF.id) == whoAssignmentTypes.ALL_STAFF.id) {
         this.calculateAnyAllStaffMembers({ assignment, who, noLocation, availableStaffMembers, availableLocations });
      }

      return assignment.noWhoAssignmentType == 0 ? [] : [assignment];
   }

   calculateWhoShouldDoForAll({ who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations }) {
      let staffAssigned = [];
      let assignments = [];

      if ((who.noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id) {
         this.calculateAllSpecificTeams({ staffAssigned, who, noLocation, availableStaffMembers, availableTeams, availablePositions, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id) {
         this.calculateAllSpecificPositions({ staffAssigned, who, noLocation, availableStaffMembers, availableTeams, availablePositions, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id) {
         this.calculateAllSpecificStaffMembers({ staffAssigned, who, noLocation, availableStaffMembers, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.ALL_TEAMS.id) == whoAssignmentTypes.ALL_TEAMS.id) {
         this.calculateAllAllTeams({ staffAssigned, who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.ALL_POSITIONS.id) == whoAssignmentTypes.ALL_POSITIONS.id) {
         this.calculateAllAllPositions({ staffAssigned, who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations });
      }

      if ((who.noWhoAssignmentType & whoAssignmentTypes.ALL_STAFF.id) == whoAssignmentTypes.ALL_STAFF.id) {
         this.calculateAllAllStaffMembers({ staffAssigned, who, noLocation, availableStaffMembers, availableLocations });
      }

      staffAssigned = _.uniqBy(staffAssigned, 'noUser');

      _.each(staffAssigned, (sa) => {
         assignments.push({
            noWhoAssignmentType: whoAssignmentTypes.SPECIFIC_STAFF.id,
            noWhoUnionType: whoUnionType.ANY.id,
            teams: [],
            positions: [],
            staff: [{ no: sa.noUser }]
         });
      });

      return assignments;
   }

   calculateAllSpecificStaffMembers({ staffAssigned, who, noLocation, availableStaffMembers, availableLocations }) {
      let allowedStaffMembers = this.peopleDomainService.determineAssignedStaffMembers({
         staffMembers: availableStaffMembers,
         locations: availableLocations,
         noLocation
      });

      let allowedNos = _.map(allowedStaffMembers, (at) => {
         return { no: at.noUser };
      });

      const matchingStaffMembers = _.intersectionBy(who.staff, allowedNos, 'no');

      if (matchingStaffMembers.length > 0) {
         _.each(matchingStaffMembers, (mm) => {
            staffAssigned.push({ noUser: mm.no });
         });
      }
   }

   calculateAllAllStaffMembers({ staffAssigned, who, noLocation, availableStaffMembers, availableLocations }) {
      let allowedStaffMembers = this.peopleDomainService.determineAssignedStaffMembers({
         staffMembers: availableStaffMembers,
         locations: availableLocations,
         noLocation
      });

      if (allowedStaffMembers.length > 0) {
         _.each(allowedStaffMembers, (mm) => {
            staffAssigned.push(mm);
         });
      }
   }

   calculateAllSpecificPositions({ staffAssigned, who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations }) {
      const matchingPositions = _.intersectionBy(availablePositions, who.positions, 'no');

      let matchingMembers = this.peopleDomainService.determineMatchingMembers({
         staffMembers: availableStaffMembers,
         positions: matchingPositions,
         teams: availableTeams,
         locations: availableLocations,
         noLocation
      });

      if (matchingMembers.length > 0) {
         _.each(matchingMembers, (mm) => {
            staffAssigned.push(mm);
         });
      }
   }

   calculateAllAllPositions({ staffAssigned, who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations }) {
      let matchingMembers = this.peopleDomainService.determineMatchingMembers({
         staffMembers: availableStaffMembers,
         positions: availablePositions,
         teams: availableTeams,
         locations: availableLocations,
         noLocation
      });

      if (matchingMembers.length > 0) {
         _.each(matchingMembers, (mm) => {
            staffAssigned.push(mm);
         });
      }
   }

   calculateAllSpecificTeams({ staffAssigned, who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations }) {
      const matchingTeams = _.intersectionBy(availableTeams, who.teams, 'no');

      let matchingMembers = this.peopleDomainService.determineMatchingMembers({
         staffMembers: availableStaffMembers,
         positions: availablePositions,
         teams: matchingTeams,
         locations: availableLocations,
         noLocation
      });

      if (matchingMembers.length > 0) {
         _.each(matchingMembers, (mm) => {
            staffAssigned.push(mm);
         });
      }
   }

   calculateAllAllTeams({ staffAssigned, who, noLocation, availableStaffMembers, availablePositions, availableTeams, availableLocations }) {
      let matchingMembers = this.peopleDomainService.determineMatchingMembers({
         staffMembers: availableStaffMembers,
         positions: availablePositions,
         teams: availableTeams,
         locations: availableLocations,
         noLocation
      });

      if (matchingMembers.length > 0) {
         _.each(matchingMembers, (mm) => {
            staffAssigned.push(mm);
         });
      }
   }
}

WhoByCalculator.$Inject = ['IPeopleDomainService'];

module.exports = WhoByCalculator;
