import React, { Component } from 'react';
import TaskTypeSelector from '../TaskTypeSelector';

import { AutoFocusTextField } from '../../../components/ux/Inputs';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import RoutineSchedule from '../../../components/routines/RoutineSchedule';
import LocationAssigner from '../../../components/locations/LocationAssigner';
import PeopleAssigner from '../../../components/people/PeopleAssigner';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ContentDivider } from '../../../components/ux/Dividers';
import Switch from '@material-ui/core/Switch';

const NumberButton = (props) => <Avatar className='number size-30 bg-secondary'>{props.number}</Avatar>;

class RoutineViewer extends Component {
   constructor(props) {
      super(props);
   }

   renderBasics(number = 1) {
      const { currentRoutine, availableTaskTypes } = this.props;
      const { name, noTaskType } = currentRoutine;
      const selectedTaskType = { no: noTaskType };

      const selected = _.find(availableTaskTypes, (t) => {
         return t.no === noTaskType;
      });

      const taskTypeName = selected ? selected.name : 'unknown';

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column contents'>
               <Typography className='bullet-point'>The basics</Typography>
               <ContentDivider caption={'Type of operation'} hideDivider />
               <Typography className='readonly-txt'>{taskTypeName}</Typography>
            </div>
         </div>
      );
   }

   renderWhen(number = 2) {
      const { currentRoutine } = this.props;

      const {
         idOccurrenceType,
         dayFlags,
         datesFlags,
         monthFlags,
         cadenceFlags,
         idCadenceType,
         cadence,
         repeatsUntilDateLocal,
         dueByDateLocal,
         dueByTime,
         noSlotDueBy,
         startFromTime,
         noSlotStartFrom
      } = currentRoutine;

      const occurs = { idOccurrenceType, dayFlags, datesFlags, monthFlags, cadenceFlags, idCadenceType, cadence, repeatsUntilDateLocal };

      const workWindow = { dueByDateLocal, dueByTime, noSlotDueBy, startFromTime, noSlotStartFrom };

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column contents'>
               <Typography className='bullet-point'>How often should this be done?</Typography>
               <RoutineSchedule readOnly={true} occurs={occurs} workWindow={workWindow} />
            </div>
         </div>
      );
   }

   renderWhere(number = 3) {
      const { currentRoutine, availableTags, availableLocations } = this.props;

      console.log('availableLocations', availableLocations);

      const { actionedAt } = currentRoutine;

      return (
         <div className='section  d-flex justify-content-start flex-row '>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column contents'>
               <Typography className='bullet-point'>Where should this be done?</Typography>
               <LocationAssigner
                  availableLocations={availableLocations}
                  availableTags={availableTags}
                  readOnly={true}
                  assignment={actionedAt}
                  unassignedLabel={'Click to specify locations'}
                  allLocationsLabel={'at all locations'}
                  allLocationsOption={'all locations'}
                  workQuestionLabel={'Where is this task perfomed?'}
                  actionWord={''}
                  forceSpecificLocation
                  atCertainLocationsOption={'at specific locations'}
               />
            </div>
         </div>
      );
   }

   renderWho(number = 4) {
      const { currentRoutine, availableStaff, availableTeams, availablePositions } = this.props;

      const { who, signoff } = currentRoutine;

      const { requiresSignoff, noSignoffAssignmentType } = signoff;

      const signoffWho = {
         noWhoAssignmentType: noSignoffAssignmentType,
         noWhoUnionType: 1,
         teams: signoff.teams,
         positions: signoff.positions,
         staff: signoff.staff
      };
      return (
         <div className='section  d-flex justify-content-start flex-row contents'>
            <div className='number-wrapper'>
               <NumberButton number={number} />
            </div>
            <div className='d-flex justify-content-start flex-column '>
               <Typography className='bullet-point'>Who should do this?</Typography>
               <PeopleAssigner readOnly={true} who={who} teams={availableTeams} positions={availablePositions} staff={availableStaff} />
               <br />
               {}
               {requiresSignoff && (
                  <>
                     <FormControlLabel
                        control={<Switch color='primary' disabled={true} checked={requiresSignoff} />}
                        label={'Sign off required for this operation.'}
                     />
                     <PeopleAssigner
                        readOnly={true}
                        unassignedLabel={'select who should signoff this operation.'}
                        showUnionSelector={false}
                        who={signoffWho}
                        teams={availableTeams}
                        positions={availablePositions}
                        staff={availableStaff}
                     />
                  </>
               )}
            </div>
         </div>
      );
   }

   render() {
      return (
         <div className='RoutineViewer'>
            {this.renderBasics()}
            {this.renderWhen()}
            {this.renderWhere()}
            {this.renderWho()}
         </div>
      );
   }
}

RoutineViewer.defaultProps = {
   availableLocations: [],
   availableTeams: [],
   availablePositions: [],
   availableStaff: [],
   availableTags: [],
   availableTaskTypes: []
};

export default RoutineViewer;
