import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
//import {Redirect, Route, Switch} from 'react-router-dom';
import App from '../App';
import { AccountStore, Component } from '../client';
import FeedbackManager from '../components/feedback/FeedbackManager';
import NavigationManager from '../components/navigation/NavigationManager';
import theme from '../theme';
import NotFound from './NotFound';
import LogIn from './LogIn';

const RestrictedRoute = ({ path, authUser, component: Component }) => (
   <Route
      path={path}
      render={(props) =>
         authUser ? (
            <Component {...props} />
         ) : (
            <Redirect
               to={{
                  pathname: '/login',
                  state: { from: props.location }
               }}
            />
         )
      }
   />
);

export default class MainApp extends Component {
   constructor() {
      super();

      this.stores = [AccountStore];

      this.storeKeys = ['authUser', 'locale'];
   }

   render() {
      const { match, location } = this.props;

      const { authUser } = this.state;

      const locale = 'en';

      if (location.pathname === '/') {
         if (authUser === null) {
            return <Redirect to={'/login'} />;
         } else {
            //return ( <Redirect to={'/app/settings/sandbox'}/> );
            //return ( <Redirect to={'/app/issues'}/> );
            //return ( <Redirect to={'/app/issues/raise'}/> );
            //return <Redirect to={'/app/home'} />;
            //return <Redirect to={'/app/locations'} />;
            //return ( <Redirect to={'/app/do'}/> );
            //return ( <Redirect to={'/app/plan'}/> );
            //return ( <Redirect to={'/app/users/profile'}/> );
            //return <Redirect to={'/app/tasks'} />;
            /*
				return (
					<Redirect
						to={
							'/reset-password?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJoYXNoIjoiQjg0QzhGRkEtQ0VBNi00REJBLUEzRTYtQUNENkM4RTZDQkM3IiwiZW1haWwiOiJtYXJrX2VnZ2Vyc0Bob3RtYWlsLmNvbSIsImlhdCI6MTYzMzE4NTIxNSwiZXhwIjoxNjMzMTg4ODE1fQ.RO_XI5ACA6Hc6OinTaGyCKimLh4986zxRcUCb5Dln1E'
						}
					/>
				);
				*/
            return <Redirect to={'/app/act'} />;
            //return <Redirect to={'/app/observe'} />;
            //return <Redirect to={'/app/settings/sandbox/general'} />;
            //return <Redirect to={'/app/operations'} />;

            //return <Redirect to={'app/settings/sandbox/selectors'} />;

            //return <Redirect to={'/app/account/issueflags'} />;

            //return <Redirect to={'/app/operations'} />;
            //return <Redirect to={'/app/operations/create'} />;
            //return <Redirect to={'/app/locations'} />;
            //return <Redirect to={'/app/people'} />;
            //return <Redirect to={'/app/people/teams'} />;
            //return <Redirect to={'/app/people/positions'} />;
            //return <Redirect to={'/app/locations'} />;
            //return <Redirect to={'/app/people/staff'} />;
            //return <Redirect to={'/app/settings/sandbox/media'} />;
            //return <Redirect to={'/app/settings/sandbox/resources'} />;
            //return <Redirect to={'app/settings/sandbox/dates'} />;
            //return <Redirect to={'app/settings/sandbox/forms'} />;
            //return <Redirect to={'app/settings/sandbox/inputs'} />;

            //return <Redirect to={'app/settings/sandbox/filters'} />;
            //return <Redirect to={'app/settings/sandbox/dates'} />;
            //return ( <Redirect to={'/app/plan/edit/a5d81fa3-d9d6-4dfd-b2a1-5518cb1eb49a'}/> );
            //return <Redirect to={'/app/settings/sandbox/alerts'} />;
            //return ( <Redirect to={'/app/plan'}/> );
            //return <Redirect to={'/app/locations'} />;
            //return <Redirect to={'/app/settings/sandbox'} />;

            //return <Redirect to={'/app/settings/sandbox/forms'} />;
            //return ( <Redirect to={'/app/settings/sandbox/avatars'}/> );
            //return <Redirect to={'/app/settings/sandbox'} />;
            //return ( <Redirect to={'/app/tasks'}/> );
            //return ( <Redirect to={'/app/locations/add'}/> );
            //return ( <Redirect to={'/app/tasks'}/> );
         }
      }

      return (
         <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
               <CssBaseline />
               <div className='app-main'>
                  <FeedbackManager />
                  <NavigationManager />
                  <Switch>
                     <RestrictedRoute path={`${match.url}app`} authUser={authUser} component={App} />
                     <Route path='/login' component={LogIn} />
                     <Route path='*' component={NotFound} />
                  </Switch>
               </div>
            </MuiPickersUtilsProvider>
         </ThemeProvider>
      );
   }
}
