import ApplicationActions from '../actions/application.actions.js';
import SettingsActions from '../actions/settings.actions.js';
import AccountActions from '../actions/account.actions.js';
import LocationActions from '../actions/location.actions.js';
import PeopleActions from '../actions/people.actions.js';
import StaffMemberActions from '../actions/staffmember.actions.js';
import Refluxr from '../../lib/refluxr';
import { AuthWrapper } from '../services';
import config from '../core/common';
import SettingsStore from './settings.store';
import AccountStore from './account.store';
import LocationStore from './location.store.js';
import TeamStore from './team.store.js';
import StaffMemberStore from './staffmember.store.js';

const INIT_STATE = {
   offline: false,
   airplane: false
};

class ApplicationStore extends Refluxr.Store {
   constructor() {
      super();

      this.state = INIT_STATE;

      this.listenables = ApplicationActions;

      // this.pingHandle = null;
      // this.doPing = this.doPing.bind(this);

      // this.startPinging();

      this.startTimer();

      this.tickHandle = null;
   }

   stopTimer() {
      if (this.tickHandle != null) {
         clearInterval(this.tickHandle);
      }
   }

   startTimer() {
      if (this.tickHandle != null) {
         clearInterval(this.tickHandle);
      }

      this.tickHandle = setInterval(() => {
         let actions = this.calculatePendingActions({ state: this.getFullState() });
         for (let i = 0; i < actions.length; i++) {
            actions[i]();
         }
      }, 1000);
   }

   getFullState() {
      return {
         settingsState: { ...SettingsStore.singleton.state },
         accountState: { ...AccountStore.singleton.state },
         locationState: { ...LocationStore.singleton.state },
         teamState: { ...TeamStore.singleton.state },
         staffState: { ...StaffMemberStore.singleton.state }
      };
   }

   calculateSecondsSince(lastTime, now, defaultWaitTime) {
      // Convert lastTime to a Date object if it's a string
      if (typeof lastTime === 'string') {
         lastTime = new Date(lastTime);
      }

      // Check if lastTime is a valid Date object
      if (lastTime instanceof Date && !isNaN(lastTime)) {
         // Calculate the difference in milliseconds and convert to seconds
         return (now.getTime() - lastTime.getTime()) / 1000;
      } else {
         // If lastTime is not a valid Date object, return the defaultWaitTime
         return defaultWaitTime;
      }
   }

   calculatePendingActions({ state }) {
      const ONE_MINUTE_IN_SECONDS = 60;
      const FIVE_MINUTES_IN_SECONDS = 300;
      const FIFTEEN_MINUTES_IN_SECONDS = 900;

      let actions = [];
      const now = new Date();

      const { lastAccountSettingsRefresh, lastAppSettingsRefresh } = state.settingsState;
      const { authUser, lastAccountRefresh } = state.accountState;
      const { lastLocationsRefresh } = state.locationState;
      const { lastPeopleRefresh } = state.teamState;
      const { lastStaffRefresh } = state.staffState;

      const isLoggedIn = authUser != null;

      // app settings...
      if (lastAppSettingsRefresh == null) {
         actions.push(SettingsActions.fetchAppSettings);
      }

      if (!isLoggedIn) {
         return actions;
      }

      // account settings...
      if (this.calculateSecondsSince(lastAccountSettingsRefresh, now, FIVE_MINUTES_IN_SECONDS) >= FIVE_MINUTES_IN_SECONDS) {
         actions.push(SettingsActions.getAccountSettings);
      }

      // account...
      if (lastAccountRefresh == null) {
         actions.push(AccountActions.getAccount);
      }

      // territory...
      if (lastLocationsRefresh == null) {
         actions.push(LocationActions.getTerritory);
      }

      // people...
      if (lastPeopleRefresh == null) {
         actions.push(PeopleActions.getPeople);
      }

      // staff...
      if (lastStaffRefresh == null) {
         actions.push(StaffMemberActions.fetchAllStaffMembers);
      }

      return actions;
   }

   /*
   startPinging = () => {
      if (this.pingHandle != null) {
         return;
      }

      this.pingHandle = setInterval(this.doPing, 10000);
      this.doPing();
   };

   stopPinging() {
      if (this.pingHandle != null) {
         clearInterval(this.pingHandle);
         this.pingHandle = null;
      }
   }

   confirmOnline() {
      const { offline } = this.state;

      if (offline) {
         this.setState({ offline: false });
      }
   }

   confirmOffline() {
      const { offline } = this.state;

      if (!offline) {
         this.setState({ offline: true });
      }
   }

   validateOffline() {
      if (!this.state.offline) {
         this.setState({ offline: true });
      }

      this.startPinging();
   }

   networkOnline() {
      if (this.state.airplane) {
         this.setState({ airplane: false });
      }

      this.startPinging();
   }

   networkOffline() {
      if (!this.state.airplane) {
         this.setState({ airplane: true });
      }

      this.startPinging();
   }

   doPing() {
      // do not name this ping! else will handle the action

      ApplicationActions.ping({ retrycount: 0 });
   }

   onPingCompleted(response) {
      this.stopPinging();
      this.confirmOnline();
   }

   onPingFailed(response) {
      this.confirmOffline();
   }
   */
}

export default ApplicationStore;
