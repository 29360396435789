class DomainException extends Error {
   constructor(message, status) {
      super(message);
      this.name = 'DomainException';
      this.expose = true;
      this.status = status || 422;
   }
}

DomainException.New = function (errorcode) {
   let ex = new DomainException(errorcode.message);
   ex.code = errorcode.code;
   return ex;
};

module.exports = DomainException;
