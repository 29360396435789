import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { HeatMapGrid } from 'react-grid-heatmap';
import TaskMetricsDonut from './components/TaskMetricsDonut';
import _ from 'lodash';

const interpolateColor = (value, min, max) => {
   const range = max - min;
   const ratio = (value - min) / range;

   // Interpolate between red (0) to orange (5) to green (10)
   const r = ratio < 0.5 ? 255 : Math.floor(255 - (ratio - 0.5) * 2 * 255);
   const g = ratio < 0.5 ? Math.floor(ratio * 2 * 165) : 165;
   const b = 0;

   return `rgb(${r}, ${g}, ${b})`;
};

const TaskMetricsHeatMap = ({ locations = [], metricsRawData = null, aggregationPeriod = 'daily' }) => {
   const [open, setOpen] = useState(false);
   const [locationMetrics, setLocationMetrics] = useState([]);
   const [startingIndex, setStartingIndex] = useState(0);

   const onCloseRequested = () => {
      setOpen(false);
   };

   const calcs = useMemo(() => {
      if (metricsRawData == null || locations.length == 0) {
         return { visible: false, xLabels: [], yLabels: [], data: [], lookup: {} };
      }

      const uniqueNoLocations = _.uniq(
         _.flatMap(Object.values(metricsRawData), (dateEntries) => dateEntries.map((entry) => entry.noLocation))
      );

      const availableLocations = locations.filter((location) => uniqueNoLocations.includes(location.no));

      // Get the first key of metricsRawData as the starting date
      var startingDate = Object.keys(metricsRawData)[0];

      // Ensure the date is valid
      if (!moment(startingDate, 'YYYY-MM-DD', true).isValid()) {
         startingDate = moment().format('YYYY-MM-DD'); // Fallback to current date
      }

      let xLabels = [];
      if (aggregationPeriod === 'daily') {
         xLabels = Array.from({ length: 7 }, (_, i) => {
            const date = moment(startingDate).startOf('week').add(i, 'days');
            return {
               key: date.format('YYYY-MM-DD'),
               value: date.format('Do MMM YYYY (ddd)')
            };
         });
      } else if (aggregationPeriod === 'weekly') {
         const startDate = moment(startingDate);
         const currentYear = moment().year();

         xLabels = Array.from({ length: 13 }, (_, i) => {
            const date = moment(startDate).add(i, 'weeks');
            const weekNumber = date.week();
            const year = date.year();
            return {
               key: date.format('YYYY-MM-DD'),
               value: year !== currentYear ? `Week ${weekNumber} (${year})` : `Week ${weekNumber}`
            };
         });
      } else if (aggregationPeriod === 'monthly') {
         const startMonth = moment(startingDate).month();
         const months = moment.months();
         xLabels = [...months.slice(startMonth), ...months.slice(0, startMonth)].map((month, index) => {
            const date = moment(startingDate).add(index, 'months').startOf('month');
            return {
               key: date.format('YYYY-MM-DD'),
               value: month
            };
         });
      }

      const yLabels = availableLocations.map((location) => location.fullName);

      const data = [];
      const lookup = {};

      availableLocations.forEach((location, y) => {
         const row = [];
         xLabels.forEach((label, x) => {
            const dateData = metricsRawData[label.key];
            if (dateData) {
               const locationData = dateData.find((item) => item.noLocation === location.no);
               if (locationData && locationData.metric !== null) {
                  row.push(parseFloat(locationData.metric));
                  lookup[`${x},${y}`] = locationData;
               } else {
                  row.push(null);
               }
            } else {
               row.push(null);
            }
         });
         data.push(row);
      });

      return { visible: true, xLabels, yLabels, data, lookup };
   }, [locations, metricsRawData, aggregationPeriod]);

   const onCellClicked = (y, x) => {
      var locationMetrics = [];

      for (let i = 0; i < calcs.xLabels.length; i++) {
         let metricData = calcs.lookup[`${i},${y}`];

         locationMetrics.push({
            locationName: calcs.yLabels[y],
            period: calcs.xLabels[i].value,
            metricData: metricData ?? null
         });
      }

      setStartingIndex(x);
      setLocationMetrics(locationMetrics);
      setOpen(true);
   };

   return (
      <div
         style={{
            width: '100%',
            height: '100%',
            fontFamily: 'sans-serif'
         }}>
         {calcs.visible && (
            <div style={{ padding: '10px' }}>
               <HeatMapGrid
                  data={calcs.data}
                  xLabels={calcs.xLabels.map((label) => label.value)}
                  yLabels={calcs.yLabels}
                  cellRender={(x, y, value) => <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>}
                  xLabelsStyle={(index) => ({
                     color: '#777',
                     fontSize: '.65rem'
                  })}
                  yLabelsStyle={() => ({
                     fontSize: '.65rem',
                     textTransform: 'uppercase',
                     color: '#777'
                  })}
                  cellStyle={(y, x, ratio) => {
                     const cellData = calcs.lookup[`${x},${y}`];
                     const value = cellData ? cellData.metric : null;

                     return {
                        borderWidth: '1px 1px 1px 1px',
                        borderStyle: 'solid',
                        borderColor: '#E0E0E0',
                        background: cellData ? interpolateColor(parseFloat(value), 0, 10) : 'white',
                        fontSize: '.8rem',
                        color: '#000'
                     };
                  }}
                  cellHeight='1.5rem'
                  xLabelsPos='bottom'
                  onClick={onCellClicked}
               />
               <>
                  <Dialog className={'TaskMetricsDialog'} open={open} onClose={onCloseRequested} aria-labelledby='tag-dialog-selector'>
                     <DialogContent>
                        <TaskMetricsDonut locationMetrics={locationMetrics} startingIndex={startingIndex} />
                     </DialogContent>
                  </Dialog>
               </>
            </div>
         )}
      </div>
   );
};

export default TaskMetricsHeatMap;
