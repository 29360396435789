const _ = require('../../../lodash');
const constants = require('../../../constants');

const { whoAssignmentTypes, whoUnionType } = constants;

class WhoCaptionBuilder {
   build({ who, unassignedLabel = 'not yet assigned to anyone', availablePositions, availableTeams, availableStaffMembers }) {
      let captionBuilder = null;

      const noWhoAssignmentType = typeof who.noWhoAssignmentType == 'undefined' ? who.noSignoffAssignmentType : who.noWhoAssignmentType;

      if (noWhoAssignmentType == whoAssignmentTypes.UNASSIGNED.id) {
         captionBuilder = this.buildCaptionUnassigned.bind(this);
      } else {
         captionBuilder = this.buildCaptionSpecific.bind(this);
      }

      let caption = captionBuilder({ who, unassignedLabel, availablePositions, availableTeams, availableStaffMembers });

      return `${caption}`;
   }

   buildCaptionUnassigned({ who, unassignedLabel }) {
      let caption = unassignedLabel;

      return `${caption}`.trim();
   }

   buildCaptionSpecific({ who, availablePositions, availableTeams, availableStaffMembers }) {
      const { teams, positions, staff, noWhoUnionType } = who;

      const teamCount = teams.length;
      const positionCount = positions.length;
      const staffCount = staff.length;

      let caption = '';

      let teamCaption = teamCount > 0 ? this.buildTeamsCaption({ who, availableTeams }) : '';
      let positionCaption = positionCount > 0 ? this.buildPositionsCaption({ who, availablePositions }) : '';
      let staffCaption = staffCount > 0 ? this.buildStaffCaption({ who, availableStaff: availableStaffMembers }) : '';

      const joiningWord = noWhoUnionType == whoUnionType.ALL.id ? 'and' : 'or';

      if (teamCount > 0) {
         caption += teamCaption;
         if (positionCount > 0 || staffCount > 0) {
            caption += ` ${joiningWord} `;
         }
      }

      if (positionCount > 0) {
         caption += positionCaption;
         if (staffCount > 0) {
            caption += ` ${joiningWord} `;
         }
      }

      if (staffCount > 0) {
         caption += staffCaption;
      }

      return `${caption.charAt(0).toUpperCase() + caption.slice(1)}`.trim();
   }

   buildStaffCaption({ who: { staff, noWhoUnionType }, availableStaff }) {
      const staffCount = staff.length;
      const actionWord = noWhoUnionType == whoUnionType.ALL.id ? '' : staffCount > 1 ? 'either' : '';
      const joiningWord = noWhoUnionType == whoUnionType.ALL.id ? 'and' : 'or';

      let staffCaption = `${actionWord} `;
      let names = [];

      _.each(staff, (l) => {
         const ml = _.find(availableStaff, (al) => {
            return al.noUser == l.no;
         });
         if (ml) {
            names.push(ml.user.name);
         }
      });

      const staffLimit = 3;
      const extraStaff = names.length > staffLimit ? names.length - staffLimit : 0;

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            staffCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == staffLimit) && names.length > 1 && idx <= staffLimit) {
            staffCaption += ` ${joiningWord} `;
         }
         if (idx < staffLimit) {
            staffCaption += n;
         }

         if (idx == staffLimit) {
            staffCaption += `${extraStaff} other`;
            if (extraStaff > 1) {
               //staffCaption += 's';
            }
         }
      });

      let noun = staffCount > 1 ? '' : '';

      return `${staffCaption} ${noun}`.trim();
   }

   buildPositionsCaption({ who: { positions, noWhoUnionType }, availablePositions }) {
      const positionCount = positions.length;
      const actionWord = noWhoUnionType == whoUnionType.ALL.id ? 'all people in the' : 'any one in the';

      let positionCaption = `${actionWord} `;
      let names = [];

      _.each(positions, (l) => {
         const ml = _.find(availablePositions, (al) => {
            return al.no == l.no;
         });
         if (ml) {
            names.push(ml.name);
         }
      });

      const positionLimit = 3;
      const extraPositions = names.length > positionLimit ? names.length - positionLimit : 0;
      const joiningWord = noWhoUnionType == whoUnionType.ALL.id ? 'and' : 'or';

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            positionCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == positionLimit) && names.length > 1 && idx <= positionLimit) {
            positionCaption += ` ${joiningWord} `;
         }
         if (idx < positionLimit) {
            positionCaption += n;
         }

         if (idx == positionLimit) {
            positionCaption += `${extraPositions} other`;
            if (extraPositions > 1) {
               //positionCaption += 's';
            }
         }
      });

      let noun = positionCount > 1 ? 'positions' : 'position';

      return `${positionCaption} ${noun}`.trim();
   }

   buildTeamsCaption({ who: { teams, noWhoUnionType }, availableTeams }) {
      const teamCount = teams.length;
      const actionWord = noWhoUnionType == whoUnionType.ALL.id ? 'all members of the' : 'any one on the';

      let teamCaption = `${actionWord} `;
      let names = [];

      _.each(teams, (l) => {
         const ml = _.find(availableTeams, (al) => {
            return al.no == l.no;
         });
         if (ml) {
            names.push(ml.name);
         }
      });

      const teamLimit = 3;
      const extraTeams = names.length > teamLimit ? names.length - teamLimit : 0;
      const joiningWord = noWhoUnionType == whoUnionType.ALL.id ? 'and' : 'or';

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            teamCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == teamLimit) && names.length > 1 && idx <= teamLimit) {
            teamCaption += ` ${joiningWord} `;
         }
         if (idx < teamLimit) {
            teamCaption += n;
         }

         if (idx == teamLimit) {
            teamCaption += `${extraTeams} other`;
            if (extraTeams > 1) {
               //teamCaption += 's';
            }
         }
      });

      let teamNoun = teamCount > 1 ? 'teams' : 'team';

      return `${teamCaption} ${teamNoun}`.trim();
   }
}

module.exports = WhoCaptionBuilder;
