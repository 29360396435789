import React, { Fragment } from 'react';
import {
   AccountStore,
   Component,
   LocationActions,
   LocationStore,
   UserStore,
   StaffMemberStore,
   StaffMemberActions
} from '../../../../client';
import TimezoneSelector from '../../../../components/dates/TimezoneSelector';
import LocationSelector from '../../../../components/locations/LocationSelector';
import LocationPopoutSelector from '../../../../components/locations/LocationPopoutSelector';
import LocationAssigner from '../../../../components/locations/LocationAssigner';
import TagSelector from '../../../../components/filters/TagSelector';

import TeamSelector from '../../../../components/teams/TeamSelector';
import TeamPopoutSelector from '../../../../components/teams/TeamPopoutSelector';

import PositionSelector from '../../../../components/positions/PositionSelector';
import PositionPopoutSelector from '../../../../components/positions/PositionPopoutSelector';

import TaskStateSelector from '../../../../components/tasks/TaskStateSelector';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { lodash as _, constants } from '../../../../common';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { LocationIcon, LocalOfferIcon } from '../../../../components/icons';
import TeamViewSelector from '../../../../components/teams/TeamFilter/components/TeamViewSelector';
import TeamFilter from '../../../../components/teams/TeamFilter';

import AccessLevelSelector from '../../../../components/security/AccessLevelSelector';

import CadenceTypeSelector from '../../../../components/dates/CadenceTypeSelector';
import OccurrenceSelector from '../../../../components/dates/OccurrenceSelector';

import RoutineSchedule from '../../../../components/routines/RoutineSchedule';
import PeopleAssigner from '../../../../components/people/PeopleAssigner';

const occurrences = constants.occurrences;
const cadenceTypes = constants.cadenceTypes;
const whoAssignmentTypes = constants.whoAssignmentTypes;
const whoUnionType = constants.whoUnionType;

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
//const icon = <LocationIcon fontSize='small' />;
//const checkedIcon = <LocationIcon color={'secondary'} fontSize='small' />;

const useStyles = makeStyles((theme) => ({
   root: {
      width: 500,
      '& > * + *': {
         marginTop: theme.spacing(3)
      }
   }
}));

const teams = [
   { no: 4, name: 'Cleaning' },
   { no: 1, name: 'FOH' },
   { no: 2, name: 'BOH' },
   { no: 3, name: 'Office' },
   { no: 5, name: 'Roofing' }
];

const locations = [
   //{ no: 0, name: 'Any Location', fullName: 'Any Location' },
   { no: 1, name: 'KZN', fullName: 'KZN' },
   { no: 2, name: 'Durban', fullName: 'KZN • Durban' },
   { no: 3, name: 'Hillcrest', fullName: 'KZN • Durban • Hillcrest' },
   {
      no: 4,
      name: 'Waterfall',
      fullName: 'KZN • Durban • Hillcrest • Waterfall'
   },
   { no: 5, name: 'Western Cape', fullName: 'Western Cape' },
   { no: 6, name: 'Cape Town', fullName: 'Western Cape • Cape Town' },
   {
      no: 7,
      name: 'Office',
      fullName: 'Western Cape • Cape Town • Office'
   }
];

const positions = [
   {
      id: 'ca91b8f8-9d4c-45f6-bd3b-49e4d099b8eb',
      no: 1,
      name: 'Waiter',
      team: {
         no: 1
      },
      deleted: false
   },
   {
      id: '4cf57973-6b52-43b1-b907-436fcacca243',
      no: 2,
      name: 'Head Chef',
      team: {
         no: 2
      },
      deleted: false
   },
   {
      id: '191a24aa-8cfe-4790-b048-8e57b05de7c5',
      no: 3,
      name: 'Sous Chef',
      team: {
         no: 2
      },
      deleted: false
   },
   {
      id: '25abca91-2338-47bc-bfae-8c66697468f7',
      no: 4,
      name: 'Accountant',
      team: {
         no: 3
      },
      deleted: false
   },
   {
      id: '18e08af9-61d6-40ba-bd9b-457f086474ef',
      no: 5,
      name: 'Cleaner',
      team: {
         no: 4
      },
      deleted: false
   }
];

class SelectorsPage extends Component {
   constructor() {
      super();

      this.stores = [LocationStore, AccountStore, UserStore, StaffMemberStore];

      //this.state = { noPriority: null, timezoneCode: 'Africa/Johannesburg', noIssueType: null, locatedAt: { no: 3, noSublocation: 2 } };
      //this.state = { noPriority: null, timezoneCode: 'Africa/Johannesburg', noIssueType: null, locatedAt: { no: null, noSublocation: null } };

      this.state = {
         accessLevelName: 'staff',
         teamViewType: { id: 0 },
         selectedTeams: [{ no: 1, name: 'FOH' }],
         selectedPositions: [{ no: 1, name: 'Waiter' }],
         teamLocation: null,
         timezoneCode: 'Africa/Johannesburg',
         availableTags: [
            { noTag: 1, name: 'unit' },
            { noTag: 2, name: 'region' },
            { noTag: 3, name: 'area' }
         ],
         availableLocations: [
            { no: 1, name: 'KZN', fullName: 'KZN' },
            { no: 2, name: 'Durban', fullName: 'KZN • Durban' },
            { no: 3, name: 'Hillcrest', fullName: 'KZN • Durban • Hillcrest' },
            {
               no: 4,
               name: 'Waterfall',
               fullName: 'KZN • Durban • Hillcrest • Waterfall'
            },
            { no: 5, name: 'Western Cape', fullName: 'Western Cape' },
            { no: 6, name: 'Cape Town', fullName: 'Western Cape • Cape Town' },
            {
               no: 7,
               name: 'Office',
               fullName: 'Western Cape • Cape Town • Office'
            }
         ],
         assignment: {
            noLocationAssignmentType: constants.locationAssignmentTypes.UNASSIGNED.id,
            all: false,
            specific: [],
            //at: [{ no: 2 }, { no: 3 }, { no: 5 }, { no: 1 }],
            at: [],
            within: [],
            atTaggedAs: null
         },
         idCadenceType: 1,
         idOccurrenceType: 3,

         occurs: {
            idOccurrenceType: 1,
            dayFlags: 0,
            datesFlags: 0,
            monthFlags: 0,
            cadenceFlags: 0,
            idCadenceType: null,
            cadence: null,
            repeatsUntilDateLocal: null
         },

         // occurs: {
         //    idOccurrenceType: occurrences.ANNUALLY.id,
         //    dayFlags: 0,
         //    datesFlags: 16,
         //    monthFlags: 96,
         //    cadenceFlags: 1,
         //    idCadenceType: cadenceTypes.ANNUALLY.id,
         //    cadence: 1,
         //    repeatsUntilDateLocal: null
         // },

         // occurs: {
         //    idOccurrenceType: 3,
         //    dayFlags: 0,
         //    datesFlags: 0,
         //    monthFlags: 0,
         //    cadenceFlags: 0,
         //    idCadenceType: 2,
         //    cadence: 1,
         //    repeatsUntilDateLocal: null
         // },
         workWindow: {
            dueByDateLocal: new Date(),
            //dueByTime: '08:00:00',
            dueByTime: null,
            noSlotDueBy: null,
            //startFromTime: '06:00:00',
            startFromTime: null,
            noSlotStartFrom: null
         },
         who: {
            //noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
            //noWhoAssignmentType: whoAssignmentTypes.SPECIFIC_TEAMS.id,
            //noWhoAssignmentType: whoAssignmentTypes.SPECIFIC_POSITIONS.id,
            noWhoAssignmentType: whoAssignmentTypes.SPECIFIC_STAFF.id,
            noWhoUnionType: whoUnionType.ALL.id,
            teams: [
               {
                  no: 2
               }
            ],
            positions: [],
            staff: [
               {
                  no: 4
               },
               {
                  no: 2
               },
               {
                  no: 3
               },
               {
                  no: 5
               }
            ]
         },
         selectedState: 2
      };

      this.storeKeys = ['allTags', 'staff'];
   }

   handleChange = (name) => (value) => {
      this.setState({
         [name]: value
      });
   };

   handleLocationsChange = ({ locations }) => {
      this.setState({ selectedLocations: locations });
   };

   handleTeamsChange = ({ teams }) => {
      console.log('handleTeamsChange', teams);

      this.setState({ selectedTeams: teams });
   };

   handlePositionsChange = ({ positions }) => {
      console.log('handlePositionsChange', positions);

      this.setState({ selectedPositions: positions });
   };

   onAssignmentChanged = ({ assignment }) => {
      console.log('--- onAssignmentChanged');
      console.log(assignment);
      console.log('-----');

      this.setState({ assignment });
   };

   onWhoChanged = ({ who }) => {
      console.log('Selectors who changed', who);
      this.setState({ who });
   };

   _onTagSelected = (data) => {
      console.log(data);
   };

   _onAccessLevelNameChange = (accessLevelName) => {
      this.setState({ accessLevelName: accessLevelName });
   };

   _onViewFilterChange = (teamViewType) => {
      this.setState({ teamViewType: teamViewType });
   };

   _onTeamFilterChange = ({ teamViewType, teamLocation }) => {
      console.log('_onTeamFilterChange', { teamViewType, teamLocation });

      this.setState({ teamViewType, teamLocation });
   };

   _onChipSelected = ({ evt, teams }) => {
      console.log('Chip selected', teams);
   };

   _onPositionChipSelected = ({ evt, positions }) => {
      console.log('Chip selected', positions);
   };

   _onOccurrenceChange = ({ idOccurrenceType }) => {
      console.log('_onOccurrenceChange', idOccurrenceType);
      this.setState({ idOccurrenceType });
   };

   _onCadenceChange = ({ idCadenceType }) => {
      console.log('cadenceTypes', idCadenceType);
      this.setState({ idCadenceType });
   };

   _onRoutineScheduleChange = ({ occurs, workWindow }) => {
      this.setState({ occurs, workWindow });
   };

   _onChangeStates = (data) => {
      console.log('_onChangeStates', data);

      this.setState({ selectedState: data.state });

      //setFilter({ state: data.state });
   };

   render() {
      const {
         timezoneCode,
         selectedPositions,
         selectedLocations,
         selectedTeams,
         assignment,
         availableTags,
         allTags,
         availableLocations,
         teamViewType,
         teamLocation,
         accessLevelName,
         idCadenceType,
         idOccurrenceType,
         occurs,
         workWindow,
         staff,
         who,
         selectedState
      } = this.state;

      const locationTags = _.filter(allTags, (t) => {
         return t.noTagType == constants.tagTypes.LOCATION.id;
      });

      console.log('who', who);

      return (
         <div className='app-wrapper SelectorsPage'>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <TaskStateSelector allowMultiple={true} key={'task-selector'} state={selectedState} onChange={this._onChangeStates} />

            {/*
            
            <TaskStateSelector
               
               onChange={onChangeStates}
               open={isStateOpen}
               setOpen={setIsStateOpen}
               onOpen={onStateOpened}
               zIndex={9000}
               zIndexInverse={1000}
            />

            
               <PositionSelector
               allowMultiple={true}
               positions={positions}
               selectedPositions={selectedPositions}
               onChange={this.handlePositionsChange}
            /> */}

            <br />
            <br />
            <RoutineSchedule
               readOnly={true}
               id={'routine-schedule'}
               occurs={occurs}
               workWindow={workWindow}
               onChange={this._onRoutineScheduleChange}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <PeopleAssigner
               //showUnionSelector={false}
               who={who}
               teams={teams}
               positions={positions}
               staff={staff}
               onChange={this.onWhoChanged}
            />
            <br />
            <br />
            <br />
            <br />
            <AccessLevelSelector accessLevelName={accessLevelName} onChange={this._onAccessLevelNameChange} />
            <br />
            <br />

            <br />

            <br />
            <PositionSelector
               allowMultiple={true}
               positions={positions}
               selectedPositions={selectedPositions}
               onChange={this.handlePositionsChange}
            />

            <br />
            <br />
            <PositionPopoutSelector
               required
               showAsChip
               readOnly={false}
               id='single'
               positions={positions}
               selectedPositions={selectedPositions}
               onChange={this.handlePositionsChange}
               onChipSelected={this._onPositionChipSelected}
            />
            <br />
            <br />
            <TeamSelector teams={teams} selectedTeams={selectedTeams} onChange={this.handleTeamsChange} />
            <br />
            <br />
            <TeamPopoutSelector
               showAsChip
               readOnly={false}
               id='single'
               teams={teams}
               selectedTeams={selectedTeams}
               onChange={this.handleTeamsChange}
               onChipSelected={this._onChipSelected}
            />
            <br />
            <br />
            <TagSelector
               tags={availableTags}
               onSelect={this._onTagSelected}
               allowMultiple={false}
               placeholder={'Select a tag below or type to filter...'}
               allowAll={false}
               inputIcon={<LocalOfferIcon />}
            />
            <br />
            <TimezoneSelector timezoneCode={timezoneCode} onChange={this.handleChange('timezoneCode')} />
            <br />
            <br />
            <LocationSelector locations={locations} selectedLocations={selectedLocations} onChange={this.handleLocationsChange} />
            <br />

            <br />
            <LocationAssigner
               availableLocations={locations}
               availableTags={locationTags}
               readOnly={false}
               onChange={this.onAssignmentChanged}
               assignment={assignment}
            />
            <br />
            <br />
            <br />

            <LocationPopoutSelector
               required
               id='single'
               locations={locations}
               selectedLocations={selectedLocations}
               onChange={this.handleLocationsChange}
            />
            <br />
            <br />
            <br />
            <CadenceTypeSelector idCadenceType={idCadenceType} onChange={this._onCadenceChange} />
            <br />
            <br />
            <OccurrenceSelector idOccurrenceType={idOccurrenceType} onChange={this._onOccurrenceChange} />
            <br />
            <br />
            <br />
            <br />
            {/* <RoutineSchedule id={'routine-schedule'} occurs={occurs} workWindow={workWindow} onChange={this._onRoutineScheduleChange} /> */}
         </div>
      );
   }
}

export default SelectorsPage;
