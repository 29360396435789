import Refluxr from '../../lib/refluxr';
import { OnlineApi } from '../services';
let container = require('../core/container');
import common from '../common';

const constants = common.constants;

function sleep(ms) {
   return new Promise((resolve) => setTimeout(resolve, ms));
}
function FormActions() {
   var Actions = Refluxr.createActions(
      [
         'ensureBlankFormSync',
         'requiresForm',
         {
            setupFormPreview: OnlineApi.send,
            prepare: function ({ noForm, name, description, scoringEnabled, scoreVisible, items }) {
               const o = {
                  messageType: 'SETUP_FORM_PREVIEW',
                  noForm,
                  name,
                  description,
                  scoringEnabled,
                  scoreVisible,
                  items
               };

               return o;
            }
         },
         {
            loadForm: OnlineApi.send,
            prepare: function ({ noForm }) {
               const o = {
                  messageType: 'FETCH_FORMS',
                  idFormViewType: 0,
                  searchText: null,
                  filters: [{ field: 'no', value: noForm }],
                  withTags: [],
                  withoutTags: [],
                  limit: 1,
                  page: 1,
                  forSelect: false
               };

               return o;
            }
         },
         ,
         'dump',
         'resetSync',
         'saveSync'
      ],
      container.getGlobalHandler(),
      container.getFeedbackHandler()
   );
   return Actions;
}

FormActions.instance = new FormActions();

export default FormActions.instance;
