import React, { useEffect, useState, useMemo } from 'react';
import { AutoFocusTextField } from '../../../components/ux/Inputs';
import PeopleAssigner from '../../../components/people/PeopleAssigner';
import { ContentDivider } from '../../../components/ux/Dividers';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const TaskReportEditor = ({ currentReport, availableStaff, availableTeams, availablePositions, onChange = () => {} }) => {
   const onNameChange = (e) => {
      let changed = { ...currentReport };
      changed.name = e.target.value;
      onChange({ report: changed });
   };

   const onDescriptionChange = (e) => {
      let changed = { ...currentReport };
      changed.description = e.target.value;
      onChange({ report: changed });
   };

   const onWhoChanged = ({ who }) => {
      let changed = { ...currentReport };
      changed.who = who;
      onChange({ report: changed });
   };

   const onRestrictedToChanged = (event) => {
      let changed = { ...currentReport };
      changed.restrictedToPlaceOfWork = event.target.checked;
      onChange({ report: changed });
   };

   const calcs = useMemo(() => {
      if (!currentReport) {
         return { disabled: true, restrictedToPlaceOfWork: false };
      }

      const { who, restrictedToPlaceOfWork } = currentReport;

      return { disabled: who && who.noWhoAssignmentType == 0, restrictedToPlaceOfWork };
   }, [currentReport]);

   return (
      <div className='d-flex justify-content-start flex-column'>
         <AutoFocusTextField
            id='name'
            label='Report name'
            margin='normal'
            value={currentReport.name}
            onChange={onNameChange}
            variant='outlined'
            autoFocus={true}
         />
         <TextField
            inputProps={{
               autoComplete: 'none'
            }}
            multiline
            minRows={4}
            variant='outlined'
            label='Description'
            style={{ marginTop: 0 }}
            //margin='normal'
            value={currentReport.description}
            onChange={onDescriptionChange}
            autoFocus={true}
         />
         <ContentDivider caption={'Who should see this on their dashboard?'} hideDivider />
         <PeopleAssigner
            unassignedLabel={'not shown to anyone'}
            showUnionSelector={false}
            who={currentReport.who}
            teams={availableTeams}
            positions={availablePositions}
            staff={availableStaff}
            onChange={onWhoChanged}
         />
         <br />
         {
            <FormControlLabel
               control={
                  <Switch
                     disabled={calcs.disabled}
                     color='primary'
                     checked={calcs.restrictedToPlaceOfWork}
                     onChange={onRestrictedToChanged}
                  />
               }
               label={'Include only tasks from where they work.'}
            />
         }
      </div>
   );
};

export default TaskReportEditor;
