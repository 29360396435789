import React from 'react';

import {
   Component,
   ErrorsStore,
   ApplicationStore,
   SettingsStore,
   NavigationStore,
   TaskStore,
   AccountStore,
   LocationStore,
   FormStore,
   TeamStore,
   PositionStore,
   StaffMemberStore
} from './app/client';
import MainApp from './app/containers/MainApp';
import Forgot from './app/containers/Forgot';
import ResetPassword from './app/containers/ResetPassword';
import { HashRouter, Route, Switch } from 'react-router-dom';
import SyncStore from './app/stores/sync.store';
import config from './app/core/common';

export default class BootStrapper extends Component {
   constructor(props) {
      super(props);

      // Ensure stores are loaded
      this.stores = [
         SyncStore,
         SettingsStore,
         ErrorsStore,
         LocationStore,
         PositionStore,
         //OfflineDetectionStore,
         ApplicationStore,
         AccountStore,
         NavigationStore,
         TaskStore,
         StaffMemberStore,
         FormStore,
         TeamStore
      ];
   }

   componentDidMount() {
      window._OPS = {
         environment: config.environment,
         deployedTo: config.deployedTo,
         releaseNo: config.releaseno,
         endpoint: config.endpoint,
         version: config.version
      };
   }

   render() {
      const { match } = this.props;

      return (
         <HashRouter>
            <Switch>
               <Route path={`/reset-password`} component={ResetPassword} />
               <Route path={`/forgot`} component={Forgot} />
               <Route path='/' component={MainApp} />
            </Switch>
         </HashRouter>
      );
   }
}
