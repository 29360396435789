const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const constants = require('../../constants');
const { locationAssignmentTypes, routineStates, whoAssignmentTypes, whoUnionType, taskClasses, occurrences } = constants;
const moment = require('moment-timezone');

class Routine extends AggregateRoot {
   constructor({ user, logger, sequenceProvider }) {
      super({ user, logger });

      this.sequenceProvider = sequenceProvider;
   }

   get agt() {
      return 'Routine';
   }

   async initNew({
      name,
      description,
      idOccurrenceType,
      dayFlags,
      datesFlags,
      monthFlags,
      cadenceFlags,
      idCadenceType,
      cadence,
      repeatsUntilDateLocal,
      dueByDateLocal,
      dueByTime,
      noSlotDueBy,
      startFromTime,
      noSlotStartFrom,
      noForm,
      noFormPreview,
      noTaskType
   }) {
      const tid = this.domainUser.tid;

      let noRoutine = await this.sequenceProvider.getNextRoutineNo({ tid });

      const { dueByDateLocalSanitized, startFromTimeSanitized, dueByTimeSanitized } = this.sanitizeDueFromRange({
         idOccurrenceType,
         dueByDateLocal,
         startFromTime,
         dueByTime
      });

      let p = {
         aid: newGuid(),
         noRoutine,
         name,
         description,
         idOccurrenceType,
         dayFlags,
         datesFlags,
         monthFlags,
         cadenceFlags,
         idCadenceType,
         cadence,
         repeatsUntilDateLocal,
         dueByDateLocal: dueByDateLocalSanitized,
         dueByTime: dueByTimeSanitized,
         noSlotDueBy,
         startFromTime: startFromTimeSanitized,
         noSlotStartFrom,
         state: routineStates.DISABLED | routineStates.SETTING_UP, // Initially disabled and only setup when items are added
         tid,
         scoring: {
            enabled: false,
            scoreVisible: false,
            finalGradeVisible: false,
            passFailEnabled: false,
            passFailThreshold: null
         },
         actionedAt: {
            noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED.id,
            all: false,
            specifc: [],
            at: [],
            within: [],
            atTaggedAs: null
         },
         who: {
            noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
            noWhoUnionType: whoUnionType.NONE.id,
            teams: [],
            positions: [],
            staff: []
         },
         signoff: {
            requiresSignoff: false,
            noSignoffAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
            teams: [],
            positions: [],
            staff: []
         },
         what: {
            noForm,
            noFormPreview
         },
         noTaskClass: taskClasses.OPERATION.id,
         noTaskType
      };

      this.apply('ROUTINE_ADDED', p);

      var o = _.omit(p, ['aid', 'tid']);

      this.setData(
         {
            properties: _.merge(
               {
                  id: p.aid
               },
               o
            )
         },
         true
      );
   }

   completeSetupAsNeeded() {
      if (!this.isInSetup()) {
         return;
      }
      var p = {};

      p.noRoutine = this._properties.noRoutine;
      p.state = this._properties.state & ~routineStates.SETTING_UP;

      this.apply('ROUTINE_SETUP', p);

      this._properties.state = this._properties.state & ~routineStates.SETTING_UP;
   }

   enable() {
      var p = {};

      p.noRoutine = this._properties.noRoutine;
      p.state = this._properties.state & ~routineStates.DISABLED;

      this.apply('ROUTINE_ENABLED', p);

      this._properties.state = this._properties.state & ~routineStates.DISABLED;
   }

   disable() {
      var p = {};

      p.noRoutine = this._properties.noRoutine;
      p.state = this._properties.state | routineStates.DISABLED;

      this.apply('ROUTINE_DISABLED', p);

      this._properties.state = this._properties.state | routineStates.DISABLED;
   }

   tag({ tag }) {
      const { noTag, name, noTagType, noParent } = tag;

      this._properties.tags = this._properties.tags || [];
      let tags = this._properties.tags;

      var i = _.find(tags, (x) => {
         return x.noTag == noTag;
      });
      if (i) {
         return;
      } // already tagged

      var p = {
         name,
         noTagType,
         noTag
      };
      p.noRoutine = this._properties.noRoutine;

      this.apply('ROUTINE_TAGGED', p);

      tags.push({ noTag });
   }

   untag({ tag }) {
      if (!tag) {
         return;
      }

      const { noTag, name, noTagType, noParent } = tag;

      this._properties.tags = this._properties.tags || [];
      let tags = this._properties.tags;

      var i = _.find(tags, (x) => {
         return x.noTag == noTag;
      });
      if (!i) {
         return;
      } // already untagged

      var p = {
         name,
         noTagType,
         noTag
      };
      p.noRoutine = this._properties.noRoutine;

      this.apply('ROUTINE_UNTAGGED', p);

      var slx = _.findIndex(tags, (x) => {
         return x.noTag === p.noTag;
      });
      if (slx > -1) {
         tags.splice(slx, 1);
      }
   }

   delete() {
      var p = { deleted: true };
      p.noRoutine = this._properties.noRoutine;

      this.apply('ROUTINE_DELETED', p);

      this._properties.deleted = true;
   }

   unassignFromEveryLocation() {
      var p = {
         noRoutine: this._properties.noRoutine,
         noLocationAssignmentType: locationAssignmentTypes.UNASSIGNED.id
      };

      this.apply('ROUTINE_UNASSIGNED_FROMEVERY_LOCATION', p);

      let actionedAt = this._properties.actionedAt;

      actionedAt.noLocationAssignmentType = p.noLocationAssignmentType;
      actionedAt.all = false;
      actionedAt.specific = [];
      actionedAt.at = [];
      actionedAt.within = [];
      actionedAt.atTaggedAs = null;
   }

   assignToAllLocations() {
      if (this._properties.actionedAt.all) {
         return;
      } // already marked as all

      var p = {
         noLocationAssignmentType: locationAssignmentTypes.ALL_LOCATIONS.id,
         noRoutine: this._properties.noRoutine
      };

      this.apply('ROUTINE_ASSIGNED_ALLLOCATIONS', p);

      let actionedAt = this._properties.actionedAt;

      actionedAt.noLocationAssignmentType = p.noLocationAssignmentType;
      actionedAt.all = true;
      actionedAt.specific = [];
      actionedAt.at = [];
      actionedAt.within = [];
      actionedAt.atTaggedAs = null;
   }

   assignToSpecificLocations({ nosLocation }) {
      var p = {
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.SPECIFIC_LOCATION.id,
         noRoutine: this._properties.noRoutine
      };

      this.apply('ROUTINE_ASSIGNED_SPECIFIC_LOCATIONS', p);

      let actionedAt = this._properties.actionedAt;

      actionedAt.noLocationAssignmentType = p.noLocationAssignmentType;
      actionedAt.all = false;
      actionedAt.specific = _.map(nosLocation, (l) => {
         return { no: l };
      });
      actionedAt.at = [];
      actionedAt.within = [];
      actionedAt.atTaggedAs = null;
   }

   assignToWorkAtLocations({ nosLocation }) {
      var p = {
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.AT_LOCATION.id,
         noRoutine: this._properties.noRoutine
      };

      this.apply('ROUTINE_ASSIGNED_WORKAT_LOCATIONS', p);

      let actionedAt = this._properties.actionedAt;

      actionedAt.noLocationAssignmentType = p.noLocationAssignmentType;
      actionedAt.all = false;
      actionedAt.specific = [];
      actionedAt.at = _.map(nosLocation, (l) => {
         return { no: l };
      });
      actionedAt.within = [];
      actionedAt.atTaggedAs = null;
   }

   assignWithinLocations({ nosLocation }) {
      var p = {
         nosLocation,
         noLocationAssignmentType: locationAssignmentTypes.WITHIN.id,
         noRoutine: this._properties.noRoutine
      };

      this.apply('ROUTINE_ASSIGNED_WITHIN_LOCATIONS', p);

      let actionedAt = this._properties.actionedAt;

      actionedAt.noLocationAssignmentType = p.noLocationAssignmentType;
      actionedAt.all = false;
      actionedAt.specific = [];
      actionedAt.at = [];
      actionedAt.within = _.map(nosLocation, (l) => {
         return { no: l };
      });
      actionedAt.atTaggedAs = null;
   }

   assignAtLocationsTaggedAs({ noTag }) {
      var p = {
         noTag,
         noLocationAssignmentType: locationAssignmentTypes.AT_TAGGED_WITH.id,
         noRoutine: this._properties.noRoutine
      };

      this.apply('ROUTINE_ASSIGNED_ATLOCATIONS_TAGGEDAS', p);

      let actionedAt = this._properties.actionedAt;

      actionedAt.noLocationAssignmentType = p.noLocationAssignmentType;
      actionedAt.all = false;
      actionedAt.specific = [];
      actionedAt.at = [];
      actionedAt.within = [];
      actionedAt.atTaggedAs = { noTag };
   }

   updateDefine({ name, description, noTaskType }) {
      const before = _.pick(this._properties, ['name', 'description', 'noTaskType']);

      var p = { after: { name, description, noTaskType }, before, noRoutine: this._properties.noRoutine };

      this.apply('ROUTINE_DEFINE_UPDATED', p);

      this._properties.name = p.after.name;
      this._properties.description = p.after.description;
      this._properties.noTaskType = p.after.noTaskType;
   }

   updateScoring({ scoringEnabled, scoreVisible, passFailEnabled, passFailThreshold, finalGradeVisible }) {
      const before = _.pick(this._properties.scoring, [
         'enabled',
         'scoreVisible',
         'finalGradeVisible',
         'passFailEnabled',
         'passFailThreshold'
      ]);

      var p = {
         after: { enabled: scoringEnabled, scoreVisible, passFailEnabled, passFailThreshold, finalGradeVisible },
         before,
         noRoutine: this._properties.noRoutine
      };

      this.apply('ROUTINE_SCORING_UPDATED', p);

      this._properties.scoring = {
         enabled: p.after.enabled,
         scoreVisible: p.after.scoreVisible,
         finalGradeVisible: p.after.finalGradeVisible,
         passFailEnabled: p.after.passFailEnabled,
         passFailThreshold: p.after.passFailThreshold
      };
   }

   updateWhen({
      idOccurrenceType,
      dayFlags,
      datesFlags,
      monthFlags,
      cadenceFlags,
      idCadenceType,
      cadence,
      repeatsUntilDateLocal,
      dueByDateLocal,
      dueByTime,
      noSlotDueBy,
      startFromTime,
      noSlotStartFrom
   }) {
      const before = _.pick(this._properties, [
         'idOccurrenceType',
         'dayFlags',
         'datesFlags',
         'monthFlags',
         'cadenceFlags',
         'idCadenceType',
         'cadence',
         'repeatsUntilDateLocal',
         'dueByDateLocal',
         'dueByTime',
         'noSlotDueBy',
         'startFromTime',
         'noSlotStartFrom'
      ]);

      const { dueByDateLocalSanitized, startFromTimeSanitized, dueByTimeSanitized } = this.sanitizeDueFromRange({
         idOccurrenceType,
         dueByDateLocal,
         startFromTime,
         dueByTime
      });

      var p = {
         after: {
            idOccurrenceType,
            dayFlags,
            datesFlags,
            monthFlags,
            cadenceFlags,
            idCadenceType,
            cadence,
            repeatsUntilDateLocal,
            dueByDateLocal: dueByDateLocalSanitized,
            dueByTime: dueByTimeSanitized,
            noSlotDueBy,
            startFromTime: startFromTimeSanitized,
            noSlotStartFrom
         },
         before,
         noRoutine: this._properties.noRoutine
      };

      this.apply('ROUTINE_WHEN_UPDATED', p);

      this._properties.idOccurrenceType = p.after.idOccurrenceType;
      this._properties.dayFlags = p.after.dayFlags;
      this._properties.datesFlags = p.after.datesFlags;
      this._properties.monthFlags = p.after.monthFlags;
      this._properties.cadenceFlags = p.after.cadenceFlags;
      this._properties.idCadenceType = p.after.idCadenceType;
      this._properties.cadence = p.after.cadence;
      this._properties.repeatsUntilDateLocal = p.after.repeatsUntilDateLocal;

      this._properties.dueByDateLocal = p.after.dueByDateLocal;
      this._properties.dueByTime = p.after.dueByTime;
      this._properties.noSlotDueBy = p.after.noSlotDueBy;
      this._properties.startFromTime = p.after.startFromTime;
      this._properties.noSlotStartFrom = p.after.noSlotStartFrom;
   }

   assignAllTeams({ noWhoUnionType }) {
      var p = {
         noRoutine: this._properties.noRoutine,
         noWhoUnionType: noWhoUnionType,
         noWhoAssignmentType: whoAssignmentTypes.ALL_TEAMS.id
      };

      this.apply('ROUTINE_ASSIGNEDTO_ALL_TEAMS', p);

      let who = this._properties.who;

      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = p.noWhoUnionType;
      who.teams = [];
      who.positions = [];
      who.staff = [];
   }

   assignAllPositions({ noWhoUnionType }) {
      var p = {
         noRoutine: this._properties.noRoutine,
         noWhoUnionType: noWhoUnionType,
         noWhoAssignmentType: whoAssignmentTypes.ALL_POSITIONS.id
      };

      this.apply('ROUTINE_ASSIGNEDTO_ALL_POSITIONS', p);

      let who = this._properties.who;

      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = p.noWhoUnionType;
      who.teams = [];
      who.positions = [];
      who.staff = [];
   }

   assignAllStaff({ noWhoUnionType }) {
      var p = {
         noRoutine: this._properties.noRoutine,
         noWhoUnionType: noWhoUnionType,
         noWhoAssignmentType: whoAssignmentTypes.ALL_STAFF.id
      };

      this.apply('ROUTINE_ASSIGNEDTO_ALL_STAFF', p);

      let who = this._properties.who;

      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = p.noWhoUnionType;
      who.teams = [];
      who.positions = [];
      who.staff = [];
   }

   assignSpecificTeams({ nosTeam, noWhoUnionType, noWhoAssignmentType }) {
      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignStaff = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignPositions ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignStaff ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id : newWhoAssignmentType;

      var p = {
         nosTeam,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id,
         noRoutine: this._properties.noRoutine,
         noWhoUnionType: noWhoUnionType,
         willAssignPositions,
         willAssignStaff
      };

      this.apply('ROUTINE_ASSIGNEDTO_SPECIFIC_TEAMS', p);

      let who = this._properties.who;
      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = noWhoUnionType;
      who.teams = _.map(nosTeam, (l) => {
         return { no: l };
      });
      who.positions = willAssignPositions ? who.positions : [];
      who.staff = willAssignStaff ? who.staff : [];
   }

   assignSpecificPositions({ nosPosition, noWhoUnionType, noWhoAssignmentType }) {
      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignTeams = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;
      let willAssignStaff = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignTeams ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignStaff ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id : newWhoAssignmentType;

      var p = {
         nosPosition,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id,
         noRoutine: this._properties.noRoutine,
         noWhoUnionType: noWhoUnionType,
         willAssignTeams,
         willAssignStaff
      };

      this.apply('ROUTINE_ASSIGNEDTO_SPECIFIC_POSITIONS', p);

      let who = this._properties.who;
      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = noWhoUnionType;
      who.positions = _.map(nosPosition, (l) => {
         return { no: l };
      });
   }

   assignSpecificStaff({ nosStaff, noWhoUnionType, noWhoAssignmentType }) {
      let { noWhoAssignmentType: noExistingWhoAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignTeams = (noWhoAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;

      let newWhoAssignmentType = noExistingWhoAssignmentType;
      newWhoAssignmentType = !willAssignTeams ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id : newWhoAssignmentType;
      newWhoAssignmentType = !willAssignPositions ? newWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id : newWhoAssignmentType;

      var p = {
         nosStaff,
         noWhoAssignmentType: newWhoAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id,
         noRoutine: this._properties.noRoutine,
         noWhoUnionType: noWhoUnionType,
         willAssignPositions,
         willAssignTeams
      };

      this.apply('ROUTINE_ASSIGNEDTO_SPECIFIC_STAFF', p);

      let who = this._properties.who;
      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = noWhoUnionType;
      who.staff = _.map(nosStaff, (l) => {
         return { no: l };
      });
   }

   unassignFromEveryone() {
      var p = {
         noRoutine: this._properties.noRoutine,
         noWhoUnionType: whoUnionType.NONE.id,
         noWhoAssignmentType: whoAssignmentTypes.UNASSIGNED.id
      };

      this.apply('ROUTINE_UNASSIGNED_FROM_EVERYONE', p);

      let who = this._properties.who;

      who.noWhoAssignmentType = p.noWhoAssignmentType;
      who.noWhoUnionType = p.noWhoUnionType;
      who.teams = [];
      who.positions = [];
      who.staff = [];
   }

   /* SIGNOFF  */

   assignSignoffToAllStaff() {
      var p = {
         noRoutine: this._properties.noRoutine,
         noSignoffAssignmentType: whoAssignmentTypes.ALL_STAFF.id,
         requiresSignoff: true
      };

      this.apply('ROUTINE_SIGNOFF_ASSIGNEDTO_ALL_STAFF', p);

      let signoff = this._properties.signoff;

      signoff.requiresSignoff = p.requiresSignoff;
      signoff.noSignoffAssignmentType = p.noSignoffAssignmentType;
      signoff.teams = [];
      signoff.positions = [];
      signoff.staff = [];
   }

   assignSignoffToAllTeams() {
      var p = {
         noRoutine: this._properties.noRoutine,
         noSignoffAssignmentType: whoAssignmentTypes.ALL_TEAMS.id,
         requiresSignoff: true
      };

      this.apply('ROUTINE_SIGNOFF_ASSIGNEDTO_ALL_TEAMS', p);

      let signoff = this._properties.signoff;

      signoff.requiresSignoff = p.requiresSignoff;
      signoff.noSignoffAssignmentType = p.noSignoffAssignmentType;
      signoff.teams = [];
      signoff.positions = [];
      signoff.staff = [];
   }

   assignSignoffToAllPositions() {
      var p = {
         noRoutine: this._properties.noRoutine,
         noSignoffAssignmentType: whoAssignmentTypes.ALL_POSITIONS.id,
         requiresSignoff: true
      };

      this.apply('ROUTINE_SIGNOFF_ASSIGNEDTO_ALL_POSITIONS', p);

      let signoff = this._properties.signoff;

      signoff.requiresSignoff = p.requiresSignoff;
      signoff.noSignoffAssignmentType = p.noSignoffAssignmentType;
      signoff.teams = [];
      signoff.positions = [];
      signoff.staff = [];
   }

   unassignSignoffFromEveryone({ requiresSignoff }) {
      var p = {
         noRoutine: this._properties.noRoutine,
         noSignoffAssignmentType: whoAssignmentTypes.UNASSIGNED.id,
         requiresSignoff
      };

      this.apply('ROUTINE_SIGNOFF_UNASSIGNED_FROM_EVERYONE', p);

      let signoff = this._properties.signoff;

      signoff.requiresSignoff = p.requiresSignoff;
      signoff.noSignoffAssignmentType = p.noSignoffAssignmentType;
      signoff.teams = [];
      signoff.positions = [];
      signoff.staff = [];
   }

   assignSignoffToSpecificTeams({ nosTeam, noSignoffAssignmentType }) {
      let { noSignoffAssignmentType: noExistingSignoffAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignStaff = (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newSignoffAssignmentType = noExistingSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignPositions
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id
         : newSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignStaff
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id
         : newSignoffAssignmentType;

      var p = {
         nosTeam,
         noSignoffAssignmentType: newSignoffAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id,
         noRoutine: this._properties.noRoutine,
         willAssignPositions,
         willAssignStaff,
         requiresSignoff: true
      };

      this.apply('ROUTINE_SIGNOFF_ASSIGNEDTO_SPECIFIC_TEAMS', p);

      let signoff = this._properties.signoff;
      signoff.requiresSignoff = p.requiresSignoff;
      signoff.noSignoffAssignmentType = p.noSignoffAssignmentType;
      signoff.teams = _.map(nosTeam, (l) => {
         return { no: l };
      });
      signoff.positions = willAssignPositions ? signoff.positions : [];
      signoff.staff = willAssignStaff ? signoff.staff : [];
   }

   assignSignoffToSpecificPositions({ nosPosition, noSignoffAssignmentType }) {
      let { noSignoffAssignmentType: noExistingSignoffAssignmentType } = this._properties.who;

      let willAssignTeams = (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;
      let willAssignStaff = (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_STAFF.id) == whoAssignmentTypes.SPECIFIC_STAFF.id;

      let newSignoffAssignmentType = noExistingSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignTeams
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id
         : newSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignStaff
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id
         : newSignoffAssignmentType;

      var p = {
         nosPosition,
         noSignoffAssignmentType: newSignoffAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id,
         noRoutine: this._properties.noRoutine,
         willAssignTeams,
         willAssignStaff,
         requiresSignoff: true
      };

      this.apply('ROUTINE_SIGNOFF_ASSIGNEDTO_SPECIFIC_POSITIONS', p);

      let signoff = this._properties.signoff;
      signoff.requiresSignoff = p.requiresSignoff;
      signoff.noSignoffAssignmentType = p.noSignoffAssignmentType;
      signoff.positions = _.map(nosPosition, (l) => {
         return { no: l };
      });
      signoff.teams = willAssignTeams ? signoff.teams : [];
      signoff.staff = willAssignStaff ? signoff.staff : [];
   }

   assignSignoffToSpecificStaff({ nosStaff, noSignoffAssignmentType }) {
      let { noSignoffAssignmentType: noExistingSignoffAssignmentType } = this._properties.who;

      let willAssignPositions =
         (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_POSITIONS.id) == whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      let willAssignTeams = (noSignoffAssignmentType & whoAssignmentTypes.SPECIFIC_TEAMS.id) == whoAssignmentTypes.SPECIFIC_TEAMS.id;

      let newSignoffAssignmentType = noExistingSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignTeams
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id
         : newSignoffAssignmentType;
      newSignoffAssignmentType = !willAssignPositions
         ? newSignoffAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id
         : newSignoffAssignmentType;

      var p = {
         nosStaff,
         noSignoffAssignmentType: newSignoffAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id,
         noRoutine: this._properties.noRoutine,
         willAssignPositions,
         willAssignTeams,
         requiresSignoff: true
      };

      this.apply('ROUTINE_SIGNOFF_ASSIGNEDTO_SPECIFIC_STAFF', p);

      let signoff = this._properties.signoff;
      signoff.requiresSignoff = p.requiresSignoff;
      signoff.noSignoffAssignmentType = p.noSignoffAssignmentType;
      signoff.staff = _.map(nosStaff, (l) => {
         return { no: l };
      });
      signoff.positions = willAssignPositions ? signoff.positions : [];
      signoff.teams = willAssignTeams ? signoff.teams : [];
   }

   /* =================== */

   isInSetup() {
      const { state } = this._properties;

      return (state & routineStates.SETTING_UP) == routineStates.SETTING_UP;
   }

   isDisabled() {
      const { state } = this._properties;

      return (state & routineStates.DISABLED) == routineStates.DISABLED;
   }

   isDeleted() {
      const { deleted } = this._properties;
      return deleted;
   }

   isEnabled() {
      return !this.isInSetup() && !this.isDisabled() && !this.isDeleted();
   }

   sanitizeDueFromRange({ idOccurrenceType, dueByDateLocal, startFromTime, dueByTime }) {
      let dueByDateLocalSanitized = dueByDateLocal;
      let startFromTimeSanitized = startFromTime;
      let dueByTimeSanitized = dueByTime;

      if (idOccurrenceType === occurrences.AS_NEEDED.id) {
         dueByDateLocalSanitized = null;
         startFromTimeSanitized = null;
         dueByTimeSanitized = null;
      } else {
         if (!this.isValidDueFromRange({ dueByDateLocal, startFromTime, dueByTime })) {
            throw new DomainException('When specifying when this first happens, the end time must be after the start time.');
         }
      }

      return { dueByDateLocalSanitized, startFromTimeSanitized, dueByTimeSanitized };
   }

   isValidDueFromRange({ dueByDateLocal, startFromTime, dueByTime }) {
      const dueByInstant = this.createInstantFromDateAndTime(dueByDateLocal, dueByTime);
      const startsFromInstant = this.createInstantFromDateAndTime(dueByDateLocal, startFromTime);

      return dueByInstant.isAfter(startsFromInstant);
   }

   createInstantFromDateAndTime(date, time) {
      const f = moment
         .utc(date)
         .startOf('day')
         .format('YYYY-MM-DD' + 'T' + time + 'Z');

      return moment.utc(f);
   }
}

module.exports = Routine;
