import React from 'react';
import { Component } from '../../../client';
import { lodash as _, constants } from '../../../common';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import classNames from 'classnames';
import { LinkButton, ToggleButton } from '../../../components/ux/Buttons';
import { Popover, PopoverBody } from '../../popovers';
import PeopleSelector from '../../../components/people/PeopleSelector';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ActionButton from '../../ux/Buttons/ActionButton';

const { whoAssignmentTypes, whoUnionType } = constants;

const inputStates = {
   SELECT_WHO: 1,
   DONE: 2
};

const buttonOptions = ['Assign to any', 'Assign to all'];

//value={whoUnionType.ANY.id}
/*
_onUnionTypeChanged = (evt) => {
      //evt.stopPropagation();
      //evt.preventDefault();
      const { who } = this.state;

      const noWhoUnionType = parseInt(evt.target.value, 10);

      who.noWhoUnionType = noWhoUnionType;

      this.setState({ who, shouldValidate: true });
   };
*/

function AssignmentButton({
   disabled = false,
   noWhoUnionType = whoUnionType.ANY.id,
   onAssignmentTypeChange = () => {},
   onAssign = () => {}
}) {
   const [open, setOpen] = React.useState(false);
   const anchorRef = React.useRef(null);
   const [selectedIndex, setSelectedIndex] = React.useState(noWhoUnionType == whoUnionType.ANY.id ? 0 : 1);

   const handleClick = () => {
      let noWhoUnionType = whoUnionType.ANY.id;
      switch (selectedIndex) {
         case 1:
            noWhoUnionType = whoUnionType.ALL.id;
            break;
         default:
            noWhoUnionType = whoUnionType.ANY.id;
            break;
      }

      onAssign(noWhoUnionType);
   };

   const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);

      console.log(buttonOptions[index]);

      let noWhoUnionType = whoUnionType.ANY.id;
      switch (index) {
         case 1:
            noWhoUnionType = whoUnionType.ALL.id;
            break;
         default:
            noWhoUnionType = whoUnionType.ANY.id;
            break;
      }

      onAssignmentTypeChange(noWhoUnionType);
   };

   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
         return;
      }

      setOpen(false);
   };

   return (
      <>
         <ButtonGroup disabled={disabled} variant='contained' color='primary' ref={anchorRef} aria-label='split button'>
            <Button className='transform-none' onClick={handleClick}>
               {buttonOptions[selectedIndex]}
            </Button>
            <Button
               color='primary'
               size='small'
               aria-controls={open ? 'split-button-menu' : undefined}
               aria-expanded={open ? 'true' : undefined}
               aria-label='select merge strategy'
               aria-haspopup='menu'
               onClick={handleToggle}>
               <ArrowDropDownIcon />
            </Button>
         </ButtonGroup>
         <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
               <Grow
                  {...TransitionProps}
                  style={{
                     transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                  }}>
                  <Paper>
                     <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id='split-button-menu'>
                           {buttonOptions.map((option, index) => (
                              <MenuItem
                                 key={option}
                                 selected={index === selectedIndex}
                                 onClick={(event) => handleMenuItemClick(event, index)}>
                                 {option}
                              </MenuItem>
                           ))}
                        </MenuList>
                     </ClickAwayListener>
                  </Paper>
               </Grow>
            )}
         </Popper>
      </>
   );
}

class PeopleAssigner extends Component {
   constructor(props) {
      super(props);

      this.state = this.buildFromProps(props);

      this.stateRenders = {};
      this.stateRenders[inputStates.SELECT_WHO] = this.renderWhoSelection.bind(this);

      this._onPopoverKeyDown = this._onPopoverKeyDown.bind(this);
      this._onAssignmentTypeChanged = this._onAssignmentTypeChanged.bind(this);
   }

   buildFromProps(props) {
      let staff = props.staff.map((l) => {
         const { name, noUser } = l.user;

         return { name, noUser };
      });

      var s = {
         who: _.cloneDeep(props.who),
         editing: props.openForEdit,
         shouldValidate: true,
         broadcast: false,
         valid: false,
         inputState: inputStates.SELECT_WHO,
         staff
      };

      return _.merge({}, s);
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      const currentWho = this.state.who;
      const nextWho = nextProps.who;
      const current = this.state;
      const next = nextProps;
      const currentStaff = this.state.staff;
      const nextStaff = nextProps.staff;

      if (
         !currentWho ||
         (nextWho && !_.isEqual(currentWho, nextWho)) ||
         !current ||
         (next && current.openForEdit != next.openForEdit) ||
         (nextStaff && !_.isEqual(currentStaff, nextStaff))
      ) {
         this.setState(this.buildFromProps(nextProps));
      }
   }

   componentDidUpdate() {
      const { broadcast, shouldValidate, who } = this.state;

      if (shouldValidate) {
         var o = this.validate({ who });
         this.setState({ ...o, shouldValidate: false });
      }

      if (broadcast) {
         const onChange = this.props.onChange;
         if (onChange) {
            onChange({ who });
         }

         this.setState({ broadcast: false });
      }
   }

   validate({ who }) {
      let valid = true;

      const { noWhoUnionType, positions, teams, staff } = who;

      const hasPositions = positions && positions.length > 0;
      const hasTeams = teams && teams.length > 0;
      const hasStaff = staff && staff.length > 0;

      valid = (hasPositions || hasTeams || hasStaff) && noWhoUnionType != whoUnionType.NONE.id;
      //TODO - flesh out

      return { valid, who };
   }

   toggleEditing = (e, changed = false) => {
      const { editing } = this.state;
      const { onPopoutClosed } = this.props;

      const newEditing = !editing;
      if (!newEditing) {
         if (onPopoutClosed) {
            onPopoutClosed();
         }
      }

      const s = !changed ? this.buildFromProps(this.props) : this.state;

      this.setState({
         ...s,
         editing: newEditing,
         inputState: inputStates.SELECT_WHO
      });
   };

   getState = () => {
      return _.cloneDeep(this.state);
   };

   buildCaptionUnassigned(who) {
      const { unassignedLabel } = this.props;

      let caption = unassignedLabel;

      return `${caption}`.trim();
   }

   buildCaptionSpecific(who) {
      const { teams, positions, staff, noWhoUnionType } = who;

      const teamCount = teams.length;
      const positionCount = positions.length;
      const staffCount = staff.length;

      //const actionWord = who.noWhoUnionType == whoUnionType.ALL.id ? 'All members of the' : 'Any one on the';

      //let caption = `${actionWord} `;
      let caption = '';

      let teamCaption = teamCount > 0 ? this.buildTeamsCaption(who) : '';
      let positionCaption = positionCount > 0 ? this.buildPositionsCaption(who) : '';
      let staffCaption = staffCount > 0 ? this.buildStaffCaption(who) : '';

      const joiningWord = noWhoUnionType == whoUnionType.ALL.id ? 'and' : 'or';

      if (teamCount > 0) {
         caption += teamCaption;
         if (positionCount > 0 || staffCount > 0) {
            caption += ` ${joiningWord} `;
         }
      }

      if (positionCount > 0) {
         caption += positionCaption;
         if (staffCount > 0) {
            caption += ` ${joiningWord} `;
         }
      }

      if (staffCount > 0) {
         caption += staffCaption;
      }

      return `${caption.charAt(0).toUpperCase() + caption.slice(1)}`.trim();
   }

   buildStaffCaption({ staff, noWhoUnionType }) {
      const { staff: availableStaff } = this.props;

      const staffCount = staff.length;
      const actionWord = noWhoUnionType == whoUnionType.ALL.id ? '' : staffCount > 1 ? 'either' : '';
      const joiningWord = noWhoUnionType == whoUnionType.ALL.id ? 'and' : 'or';

      let staffCaption = `${actionWord} `;
      let names = [];

      _.each(staff, (l) => {
         const ml = _.find(availableStaff, (al) => {
            return al.noUser == l.no;
         });
         if (ml) {
            names.push(ml.user.name);
         }
      });

      const staffLimit = 3;
      const extraStaff = names.length > staffLimit ? names.length - staffLimit : 0;

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            staffCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == staffLimit) && names.length > 1 && idx <= staffLimit) {
            staffCaption += ` ${joiningWord} `;
         }
         if (idx < staffLimit) {
            staffCaption += n;
         }

         if (idx == staffLimit) {
            staffCaption += `${extraStaff} other`;
            if (extraStaff > 1) {
               //staffCaption += 's';
            }
         }
      });

      let noun = staffCount > 1 ? '' : '';

      return `${staffCaption} ${noun}`.trim();
   }

   buildPositionsCaption({ positions, noWhoUnionType }) {
      const { positions: availablePositions } = this.props;

      const positionCount = positions.length;
      const actionWord = noWhoUnionType == whoUnionType.ALL.id ? 'all people in the' : 'any one in the';

      let positionCaption = `${actionWord} `;
      let names = [];

      _.each(positions, (l) => {
         const ml = _.find(availablePositions, (al) => {
            return al.no == l.no;
         });
         if (ml) {
            names.push(ml.name);
         }
      });

      const positionLimit = 3;
      const extraPositions = names.length > positionLimit ? names.length - positionLimit : 0;
      const joiningWord = noWhoUnionType == whoUnionType.ALL.id ? 'and' : 'or';

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            positionCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == positionLimit) && names.length > 1 && idx <= positionLimit) {
            positionCaption += ` ${joiningWord} `;
         }
         if (idx < positionLimit) {
            positionCaption += n;
         }

         if (idx == positionLimit) {
            positionCaption += `${extraPositions} other`;
            if (extraPositions > 1) {
               //positionCaption += 's';
            }
         }
      });

      let noun = positionCount > 1 ? 'positions' : 'position';

      return `${positionCaption} ${noun}`.trim();
   }

   buildTeamsCaption({ teams, noWhoUnionType }) {
      const { teams: availableTeams } = this.props;

      const teamCount = teams.length;
      const actionWord = noWhoUnionType == whoUnionType.ALL.id ? 'all members of the' : 'any one on the';

      let teamCaption = `${actionWord} `;
      let names = [];

      _.each(teams, (l) => {
         const ml = _.find(availableTeams, (al) => {
            return al.no == l.no;
         });
         if (ml) {
            names.push(ml.name);
         }
      });

      const teamLimit = 3;
      const extraTeams = names.length > teamLimit ? names.length - teamLimit : 0;
      const joiningWord = noWhoUnionType == whoUnionType.ALL.id ? 'and' : 'or';

      _.each(names, (n, idx) => {
         if ((idx == 1 || idx == 2) && names.length > 2) {
            teamCaption += ', ';
         }
         if ((idx == names.length - 1 || idx == teamLimit) && names.length > 1 && idx <= teamLimit) {
            teamCaption += ` ${joiningWord} `;
         }
         if (idx < teamLimit) {
            teamCaption += n;
         }

         if (idx == teamLimit) {
            teamCaption += `${extraTeams} other`;
            if (extraTeams > 1) {
               //teamCaption += 's';
            }
         }
      });

      let teamNoun = teamCount > 1 ? 'teams' : 'team';

      return `${teamCaption} ${teamNoun}`.trim();
   }

   buildCaption(who) {
      let captionBuilder = null;
      if (who.noWhoAssignmentType == whoAssignmentTypes.UNASSIGNED.id) {
         captionBuilder = this.buildCaptionUnassigned.bind(this);
      } else {
         captionBuilder = this.buildCaptionSpecific.bind(this);
      }

      let caption = captionBuilder(who);

      return `${caption}`;
   }

   setNextInputState = (nextInputState) => {
      if (nextInputState === inputStates.DONE) {
         this.setState({ broadcast: true, inputState: inputStates.SELECT_WHO, editing: false }, () => {
            this.toggleEditing(null, true);
         });
      } else {
         this.setState({ inputState: nextInputState });
      }
   };

   getNextInputState = () => {
      const { inputState, who } = this.state;

      let newState = inputStates.DONE;

      if (inputState === inputStates.SELECT_WHO) {
         newState = inputStates.DONE;
      }

      return newState;
   };

   _onPopoverKeyDown(evt) {
      if (evt.key === 'Enter') {
         const { valid } = this.state;
         if (valid) {
            this._onDone();
         }
      }
      if (evt.key === 'Escape') {
         this.toggleEditing();
      }
   }

   _onAssignmentTypeChanged(noWhoUnionType) {
      const { who } = this.state;

      who.noWhoUnionType = noWhoUnionType;

      this.setState({ who, shouldValidate: true });
   }

   _onUnionTypeChanged = (evt) => {
      //evt.stopPropagation();
      //evt.preventDefault();
      const { who } = this.state;

      const noWhoUnionType = parseInt(evt.target.value, 10);

      who.noWhoUnionType = noWhoUnionType;

      this.setState({ who, shouldValidate: true });
   };

   _onPeopleSelectionChanged = (who) => {
      let noWhoAssignmentType = 0;

      noWhoAssignmentType =
         who.positions.length > 0
            ? noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_POSITIONS.id
            : noWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_POSITIONS.id;
      noWhoAssignmentType =
         who.teams.length > 0
            ? noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_TEAMS.id
            : noWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_TEAMS.id;
      noWhoAssignmentType =
         who.staff.length > 0
            ? noWhoAssignmentType | whoAssignmentTypes.SPECIFIC_STAFF.id
            : noWhoAssignmentType & ~whoAssignmentTypes.SPECIFIC_STAFF.id;
      who.noWhoAssignmentType = noWhoAssignmentType;

      this.setState({ who, shouldValidate: true });
   };

   renderWhoSelection() {
      const { staff, who, valid } = this.state;
      const { teams, positions, showUnionSelector } = this.props;
      const { noWhoUnionType } = who;

      const val = noWhoUnionType != null ? noWhoUnionType : whoUnionType.ANY.id;
      const disableDone = !valid;

      let selectorLabel = '';
      if (!showUnionSelector) {
         selectorLabel = whoUnionType.ANY.id ? 'Any one of ' + selectorLabel : 'All of ' + selectorLabel;
      }

      return (
         <PopoverBody>
            <div className='who-selection'>
               <div className='who-content'>
                  {/* {showUnionSelector && (
                     <RadioGroup value={val} onChange={this._onUnionTypeChanged} className={'union-options'} style={{}}>
                        <FormControlLabel
                           value={whoUnionType.ANY.id}
                           control={<Radio key={'union-type-choice-' + whoUnionType.ANY.id.toString()} />}
                           label={'Any one of'}
                           className='option'
                        />
                        <FormControlLabel
                           className='option'
                           value={whoUnionType.ALL.id}
                           control={<Radio key={'union-type-choice-' + whoUnionType.ALL.id.toString()} />}
                           label={'All of'}
                        />
                     </RadioGroup>
                  )} */}

                  <PeopleSelector
                     label={selectorLabel}
                     teams={teams}
                     positions={positions}
                     staff={staff}
                     who={who}
                     onChange={this._onPeopleSelectionChanged}
                  />
                  <div
                     style={{
                        // backgroundColor: 'red',
                        paddingTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        width: '100%'
                     }}>
                     {showUnionSelector && (
                        <AssignmentButton
                           disabled={disableDone}
                           noWhoUnionType={val}
                           onAssignmentTypeChange={this._onAssignmentTypeChanged}
                           onAssign={this._onDone}
                        />
                     )}
                     {!showUnionSelector && <ActionButton onClick={this._onDone}>Assign</ActionButton>}
                  </div>
               </div>
            </div>
         </PopoverBody>
      );
   }

   _onDone = () => {
      this.setNextInputState(inputStates.DONE);
   };

   _onTagSelected = (data) => {
      const { who } = this.state;
      let tag = null;
      if (data.tags.length > 0) {
         tag = data.tags[0];
      }
      who.atTaggedAs = tag;

      this.setState({ who });
   };

   renderForState(inputState) {
      return <div className='canvas'>{this.stateRenders[inputState]()}</div>;
   }

   _onChipSelected = ({ evt, who }) => {
      const { onChipSelected } = this.props;

      onChipSelected({ evt, who });
   };

   render() {
      const { editing, inputState, staff } = this.state;
      const { id, label, who, readOnly, showAsChip, positions, teams, onChipSelected } = this.props;

      if (!who || (who && positions.length == 0) || (who && teams.length == 0) || (who && staff.length == 0)) {
         return <div></div>;
      }

      const className = classNames('PeopleAssigner');
      const caption = this.buildCaption(who);
      const showLabel = label != null;
      const popupTarget = id ? id + '-set-who-spn' : 'set-who-spn';

      return (
         <div className={className}>
            {readOnly && !showAsChip && (
               <div className='assign-control'>
                  {showLabel && <label>{label}</label>}
                  <span>{caption}</span>
               </div>
            )}
            {readOnly && showAsChip && (
               <Chip
                  //icon={<LocationIcon fontSize='small' />}
                  size='small'
                  color={'secondary'}
                  variant='outlined'
                  className={'info-chip'}
                  label={caption}
                  onClick={onChipSelected ? (e) => this._onChipSelected({ evt: e, who }) : undefined}

                  //onClick={(e) => this._onChipSelected({ evt: e, who })}
               />
            )}

            {!readOnly && (
               <div className='assign-control'>
                  {showLabel && <label>{label}</label>}
                  <div className='editable-caption-row'>
                     <span id={popupTarget} className='filter-link' onClick={this.toggleEditing}>
                        {caption}
                     </span>
                  </div>
               </div>
            )}
            {!readOnly && editing && (
               <Popover
                  placement='bottom'
                  isOpen={editing}
                  target={popupTarget}
                  className='who-editing-popover'
                  toggle={this.toggleEditing}
                  onKeyDown={this._onPopoverKeyDown}>
                  {this.renderForState(inputState)}
               </Popover>
            )}
         </div>
      );
   }
}

PeopleAssigner.defaultProps = {
   showUnionSelector: true,
   teams: [],
   positions: [],
   staff: [],
   unassignedLabel: 'not yet assigned to anyone'
   /*
	
	allLocationsOption: 'anywhere',
	allLocationsLabel: 'works anywhere',
	atCertainLocationsOption: 'at certain locations',
	workQuestionLabel: 'Where can they work?',
	actionWord: 'works',
	forceSpecificLocation: false,
	who: null,
	availableTags: [],
	readOnly: false,
	showAsChip: false,
	openForEdit: false,
	onChipSelected: null,
	onChange: () => {},
	onPopoutClosed: () => {},
	label: null,
	id: null,
	availableLocations: []
	*/
   /*
	availableTags: [
		{ noTag: 1, name: 'unit' },
		{ noTag: 2, name: 'region' }
	],
	*/
   /*
	availableLocations: [
		{ no: 1, name: 'KZN', fullName: 'KZN' },
		{ no: 2, name: 'Durban', fullName: 'KZN • Durban' },
		{ no: 3, name: 'Hillcrest', fullName: 'KZN • Durban • Hillcrest' },
		{
			no: 4,
			name: 'Waterfall',
			fullName: 'KZN • Durban • Hillcrest • Waterfall'
		},
		{ no: 5, name: 'Western Cape', fullName: 'Western Cape' },
		{ no: 6, name: 'Cape Town', fullName: 'Western Cape • Cape Town' },
		{
			no: 7,
			name: 'Office',
			fullName: 'Western Cape • Cape Town • Office'
		}
	]
	*/
};

export default PeopleAssigner;
